<template>
    <div>
        <div class="zoom-container overflow-auto" ref="zoomContainer">
            <div class="zoom-content" ref="zoomContent">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ZoomComponent',
    data() {
        return {
            scale: 1,
        };
    },
    methods: {
        zoomIn() {
            this.scale += 0.5;
            this.updateZoom();
        },
        zoomOut() {
            this.scale = Math.max(this.scale - 0.5, 1);
            this.updateZoom();
        },
        resetZoom() {
            this.scale = 1;
            this.updateZoom();
        },
        updateZoom() {
            const content = this.$refs.zoomContent;
            content.style.transform = `scale(${this.scale})`;
        },
    },
};
</script>
<style lang="scss" scoped>
    .zoom-content {
        transition: transform 0.2s ease;
        user-select: text !important;
        transform-origin: top left;
    }
</style>
