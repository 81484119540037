
<template> 
    <AppPermission :permission="this.$permissions.products.data">
        <div class="container my-4">
            <div class="row m-0 p-0">
                <div class="mainLeft">
                <!-- <div class="col-md-2" id="col2"> -->
                    <div class="main-title">
                        <span>{{ $t('message.ProductIndex.title') }}</span>
                    </div>
                    <div>
                        <div class="card-header mx-0 px-0" id="typeHeader" >
                        <div class="row mx-2" v-if="entries?.length > 0">
                            <div class="col-8 text-title">
                            <label>{{ $t('message.common.numberRecord') }}:</label>
                            </div>
                            <div class="col-4">
                            <select :value="numberRecord" name="number_records" id="number_records" class="number_records" @change="onChange($event)">
                                <option value="15">15</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            </div>
                        </div>
                        </div>
                    </div>
                    <!-- Loại hàng hóa -->
                    <div class="card " id="typeCard">
                        <div class="card-header mx-0 px-0 py-2" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{ $t('message.ProductIndex.product_type') }}</label>
                                </div>
                                <div class="col-3">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('cardBody','iconDown','iconUp')" >
                                        <i class="fa fa-chevron-down dp-hide" id="iconDown"></i>
                                        <i class="fa fa-chevron-up" id="iconUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 py-2" id="cardBody">
                                <div class="row">
                                    <div class="col-12 my-0 d-flex align-items-center" v-for="type in this.productTypes" :key="type.id" >
                                        <label class="container-checkbox">{{ (type.name) }}
                                            <input type="checkbox" class="chkType" @click="handleCheck(type.id)" :checked="isExistInArrayChecked(type, this.filterType)">
                                            <span class="checkmark-checkbox"></span>
                                        </label>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <!-- Nhóm hàng -->
                    <div class="card mt-4" id="GrpProduct">
                    <div class="card-header mx-0 px-0 pb-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-8">
                                    <label class="typeTitle">{{ $t('message.ProductIndex.categories') }}</label>
                                </div>
                                <div class="col-1 pl-0 ml-0">
                                    <AppPermission :permission="this.$permissions.categories.create">
                                        <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showModalCategoryAdd">
                                            <i class="fa fa-plus-circle fa-md text-secondary"></i>                                    </a>
                                    </AppPermission>
                                </div>
                                <div class="col-2">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('prdGroup','gDown','gUp')" >
                                        <i class="fa fa-chevron-down dp-hide" id="gDown"></i>
                                        <i class="fa fa-chevron-up" id="gUp"></i>
                                    </a>
                                </div>
                            </div>
                            <!-- Tìm kiếm -->
                            <div class="row mx-2 my-2 py-2 ">
                                    <div class="col-12">
                                        <div class="prd-search-box">
                                            <i class="fa fa-search mr-2 text-secondary"></i>
                                            <input type="text" class="filter-search-input w-100" v-model="inputFilterCategories" @input="onInputFilterCategories" :placeholder="$t('message.ProductIndex.categories')" />
                                        </div>
                                    </div>
                            </div>
                    </div>
                    <div class="card-body my-0 py-0" id="prdGroup">

                            <!-- Danh sách -->
                            <div class="row my-2">
                                <template v-if="this.displayCategories?.length >=1">
                                    <div class="col-12 py-2 bg-white">
                                        <span :class="{'font-weight-bold': filterCategories?.length == 0}" @click="handleCheckCategories(null)" >
                                            {{ $t('message.ProductIndex.all') }}
                                        </span>
                                    </div>
                                    <div class="col-12 py-2 bg-white" v-for="category in displayCategories" :key="category.id">
                                        <div class="d-flex flex-row justify-content-lg-between" v-on:mouseover="isEdit = category.id" v-on:mouseleave="isEdit = null">
                                            <div >
                                                <i :class="{
                                                        'fa':true,
                                                        'fa-plus': !choosedParentCategories.includes(category.id),
                                                        'px-1':true,
                                                        'fa-minus': choosedParentCategories.includes(category.id),
                                                    }"
                                                    v-if="checkHaveChildCategories(category)"
                                                    @click="onChooseParentCategory(category?.id)"
                                                />
                                                <span class="px-2 my-0" :for="`key${category.id}`" @click="handleCheckCategories(category.id)" :class="{'font-weight-bold': filterCategories.includes(category.id)}" >{{category.name}}</span>
                                            </div>
                                            <AppPermission :permission="this.$permissions.products.update">
                                                <i class="fa fa-pencil-alt" v-if="category?.id === isEdit" @click="showModalCategoryEdit(category.id)"></i>
                                            </AppPermission>
                                        </div>
                                        <div v-if="choosedParentCategories.includes(category.id)" class="child-category-container">
                                            <div  v-for="child in category?.children" :key="child?.id" :ref="`${child?.id}`" :id="`key${category.id}`" >
                                                <div class="d-flex flex-row justify-content-lg-between" v-on:mouseover="isEdit = child?.id" v-on:mouseleave="isEdit = null">
                                                    <div>
                                                        <i :class="{
                                                                'fa':true,
                                                                'fa-plus': !choosedParentCategories.includes(child?.id),
                                                                'px-1':true,
                                                                'fa-minus': choosedParentCategories.includes(child?.id),
                                                            }"
                                                            v-if="checkHaveChildCategories(child)"
                                                            @click="onChooseParentCategory(child?.id)"
                                                        />
                                                        <span class="px-2 my-0" :for="`key${child?.id}`" :class="{'font-weight-bold': filterCategories.includes(child?.id)}" @click="handleCheckCategories(child?.id)">{{child?.name}}</span>
                                                    </div>
                                                    <AppPermission :permission="this.$permissions.products.update">
                                                        <i class="fa fa-pencil-alt" v-if="child?.id === isEdit" @click="showModalCategoryEdit(child?.id)"></i>
                                                    </AppPermission>
                                                </div>
                                                <div v-if="choosedParentCategories.includes(child?.id)" class="child-category-container">
                                                    <div v-for="child2 in child?.children" :key="child2.id">
                                                        <div class="d-flex flex-row justify-content-lg-between" v-on:mouseover="isEdit = child2?.id" v-on:mouseleave="isEdit = null">
                                                            <p class="px-2 my-0" :for="`key${child2?.id}`" :class="{'font-weight-bold': filterCategories.includes(child2?.id)}" @click="handleCheckCategories(child2?.id)">{{child2?.name}}</p>
                                                            <AppPermission :permission="this.$permissions.products.update">
                                                                <i class="fa fa-pencil-alt" v-if="child2?.id === isEdit" @click="showModalCategoryEdit(child2?.id)"></i>
                                                            </AppPermission>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                    </div>
                    </div>
                    <!-- Tồn kho -->
                    <!-- <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                                <div class="row mx-2">
                                    <div class="col-9">
                                        <label class="typeTitle">{{$t('message.ProductIndex.inventory')}}</label>
                                    </div>
                                    <div class="col-1">
                                        <a href="javascript:void(0);" class="text-dark" id="linkIcon"  @click="showHide('sBody','sDown','sUp')" >
                                            <i class="fa fa-chevron-down dp-hide" id="sDown"></i>
                                            <i class="fa fa-chevron-up" id="sUp"></i>
                                        </a>
                                    </div>
                                </div>
                        </div>
                        <div class="card-body my-0 py-2" id="sBody">
                            <div class="row">
                                <div class="col-12">
                                    <label class="container ml-0">{{$t('message.common.all')}}
                                        <input type="radio" name="rdoStore" id="rdoCombo" class="chkType" checked="true">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="col-12" v-for="item in inventoryOptions" :key="item.id">
                                    <label class="container ml-0">{{item.label}}
                                        <input type="radio" name="rdoStore" id="rdoNormal" class="chkType">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- Nhà cung cấp -->
                    <!-- <div class="card mt-4" id="typeCard">
                    <div class="card-header mx-0 px-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-9">
                                <label class="typeTitle">Nhà cung cấp</label>
                            </div>
                            <div class="col-1">
                                <a href="#" class="text-dark" id="linkIcon" @click="showHide('cBody','cDown','cUp')"  >
                                    <i class="fa fa-chevron-down dp-hide" id="cDown"></i>
                                    <i class="fa fa-chevron-up" id="cUp"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 py-2" id="cBody">
                            <div class="row">
                                <div class="col-12">
                                    <q-select v-model="filter.suppliers" datasource="suppliers" id="filter_suppliers"
                                    @update:modelValue="value => doFilter('suppliers', value.join(','))" placeholder="Nhà cung cấp"
                                    :multiple="true"/>
                                </div>
                            </div>
                    </div>
                    </div> -->
                    
                    <!-- Ngày tạo -->
                    <div class="card mt-4" id="typeCard">
                    <div class="card-header mx-0 px-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-9">
                                <label class="typeTitle">
                                    {{ $t('message.ProductIndex.created_at')}}
                                </label>
                            </div>
                            <div class="col-1">
                                <a href="javascript:void(0);" class="text-dark" id="linkIcon"  @click="showHide('dBody','dDown','dUp')" >
                                    <i class="fa fa-chevron-down dp-hide" id="dDown"></i>
                                    <i class="fa fa-chevron-up" id="dUp"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 py-2" id="dBody">
                            <div class="row">
                                <div class="col-12">
                                    <div class="prd-search-box1">
                                        <InputDateRangePicker :placeholder="$t('message.ProductIndex.created_at')" @input="doFilter('created_at', filter.created_at)"
                                        v-model="filter.created_at" />
                                    </div>
                                </div>
                            </div>
                    </div>
                    </div>
                    <!-- Trạng thái hoạt động -->
                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">
                                        {{$t('message.ProductIndex.choose_show')}}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 py-2" id="sBody">
                            <div class="row">
                                <div class="col-12" v-for="item in this.statusBusiness " :key="item.id">
                                    <label class="container-radio ml-0">{{ $t(item.name) }}
                                        <input type="radio" name="sttActive" @click="doFilter('sttActive', item.id)" class="chkType" :checked="this.sttActive === item.id ? true: false">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mainRight">
                <!-- <div class="col-md-10" id="col10"> -->
                    <div class="sticky-top">
                        <div class="row">
                            <div class="col-lg-5">
                                <InputQuickSearch :placeholder="$t('message.ProductIndex.placeholder.search')" @filterSearch="changeQuickSearch" :products="true"></InputQuickSearch>
                            </div>
                            <div class="col-lg-7 d-flex justify-content-end">
                                <button type="button" @click="clearFilter()" class="btn btn-default">
                                    <i class="fa fa-filter"></i>
                                    <span class="btn-text">
                                        {{ $t('message.button.removeFilter') }}
                                    </span>
                                </button>

                                <button type="button" @click="checkAllRecord()" class="btn btn-save ml-2">
                                    <i class="fa fa-check"></i>
                                    <span class="btn-text">
                                        {{$t('message.ProductIndex.all')}}
                                    </span>
                                </button>
                                    <AppPermission :permission="this.$permissions.products.export">
                                    <button type="button" class="btn btn-save" @click="selectOptionExport()">
                                        <i class='fas fa-file-export'></i>
                                        <span class="btn-text">
                                            {{$t('message.ProductIndex.button.export_excel')}}
                                        </span>
                                    </button>
                                    </AppPermission> 
                                
                                    <div class="dropdown">
                                        <AppPermission :permission="this.$permissions.products.create ? this.$permissions.products.create : (this.$permissions.products.import ? this.$permissions.products.import : '')">
                                        <button class="btn btn-save dropdown-toggle" type="button" id="dropdownMenuButton"
                                            style="padding:7px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="fa fa-plus px-2"></i>
                                            <span class="btn-text">
                                                {{$t('message.ProductIndex.button.add')}}
                                            </span>
                                        </button>
                                        
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <AppPermission :permission="this.$permissions.products.create">
                                                <a class="dropdown-item" href="#" @click="showModalProductsAdd()">
                                                    {{$t('message.ProductIndex.button.add_product')}}
                                                </a>
                                                </AppPermission>
                                                <AppPermission :permission="this.$permissions.products.import">
                                                    <a class="dropdown-item" href="javascript:;" @click="showModalFileUploadProducts()">
                                                        {{$t('message.ProductIndex.button.upload')}}
                                                    </a>
                                                </AppPermission>
                                            
                                        </div>
                                        </AppPermission>                                  
                                
                                </div>
                                <!---Bổ sung bộ lọc hiển thị danh sách-->

                                <div id="loc" v-on:mouseleave="onMouseLeave">
                                    <SelectField :fields="fields" label="products"></SelectField>
                                </div>
                            </div>
                        </div>
                    <div v-if="entries?.length > 0">
                        <Pagination :value="paginate" :record="this.numberRecord" @change="pageChange" />
                    </div>

                        <div class="row my-2" >
                            <div class="col-sm-10" style="align-self:center" v-if="this.arrayChecked?.length > 0 && entries?.length > 0">
                                {{$t('message.common.have')}} 
                                <a href="#" v-text="this.arrayChecked?.length + ' ' + $t('message.common.product')" id="lnkCount" @click="showModalProductsChecked()"></a> 
                                {{$t('message.common.selected')}}
                                <a href="javascript:;" @click="removeChecked()">
                                    <i class="fa fa-times-circle text-danger" aria-hidden="true"></i>
                                </a>
                            </div>
                            <div class="col-sm-2 text-right" v-if="this.arrayChecked?.length > 0 && entries?.length > 0">
                                <div class="dropdown" >
                                    <button class="btn btn-save dropdown-toggle" type="button" id="dropdownMenuButton"
                                        style="padding:7px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>  
                                        {{$t('message.ProductIndex.button.option')}}
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <tamplate  v-for="items in options" :key="items.id" >
                                                <AppPermission :permission="this.$permissions.products.update">
                                                    <a v-if="items.id==4" class="dropdown-item text-dark" :target="items.target" @click="showModalProductsEdit()">
                                                        <i :class="items.icon"></i>
                                                        <label v-text="items.label"></label>
                                                        <hr class="py-0 my-0 mx-0 px-0"/>
                                                    </a>
                                                </AppPermission>
                                                <AppPermission :permission="this.$permissions.products.remove">
                                                        <a v-if="items.id==5" class="dropdown-item text-dark" :target="items.target" @click="showModalProductRemove()">
                                                        <i :class="items.icon"></i>
                                                        <label v-text="items.label"></label>
                                                        <hr class="py-0 my-0 mx-0 px-0"/>
                                                    </a>
                                                    <!-- <a v-else-if="items.id==5" class="dropdown-item text-dark" :target="items.target" @click="showModalProductRemove()">
                                                        <i :class="items.icon"></i>
                                                        <label v-text="items.label"></label>
                                                        <hr class="py-0 my-0 mx-0 px-0"/>
                                                    </a> -->
                                                </AppPermission>
                                                <a v-if="items.id==3" class="dropdown-item text-dark" :target="items.target" @click="showModalMultipleProductsBarcode('product', this.arrayChecked)">
                                                    <i :class="items.icon"></i>
                                                    <label v-text="items.label"></label>
                                                    <hr class="py-0 my-0 mx-0 px-0"/>
                                                </a>
                                                <!-- <a v-else class="dropdown-item text-dark" :href="items.link" :target="items.target" >
                                                    <i :class="items.icon"></i>
                                                    <label v-text="items.label"></label>
                                                    <hr class="py-0 my-0 mx-0 px-0"/>
                                                </a> -->

                                                <a v-if="items.id==6" href="javascript:void(0);" @click="shortCreate(items)" class="dropdown-item text-dark">
                                                    <i :class="items.icon"></i>
                                                    <label v-text="items.label"></label>
                                                    <hr class="py-0 my-0 mx-0 px-0"/>
                                                </a>

                                                <a v-if="items.id==7" href="javascript:void(0);" @click="shortCreate(items)" class="dropdown-item text-dark">
                                                    <i :class="items.icon"></i>
                                                    <label v-text="items.label"></label>
                                                    <hr class="py-0 my-0 mx-0 px-0"/>
                                                </a>
                                                <a v-if="items.id==8" href="javascript:void(0);" @click="shortCreate(items)" class="dropdown-item text-dark">
                                                    <i :class="items.icon"></i>
                                                    <label v-text="items.label"></label>
                                                    <hr class="py-0 my-0 mx-0 px-0"/>
                                                </a>
                                                <a v-if="items.id==9" href="javascript:void(0);" @click="shortCreate(items)" class="dropdown-item text-dark">
                                                    <i :class="items.icon"></i>
                                                    <label v-text="items.label"></label>
                                                    <hr class="py-0 my-0 mx-0 px-0"/>
                                                </a>
                                            </tamplate>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mx-0 my-2" v-if="getDataChecked(this.fields) > 0 && entries?.length > 0" id="divTable">
                            <!-- Bảng cấp cha -->
                            <table class="table table-index table-striped tbl-render">
                                <thead>
                                    <tr>
                                        <th style="min-width:40px;" scope="col" class="checkbox-table"><input v-model="checkedAll" @change="onCheckedAll(checkedAll)" type="checkbox" id="chkAll" /></th>
                                        <template v-for="field in fields" :key="field.field">
                                            <th scope="col" v-if="field.selected && field.field == 'name'"
                                                :class="field.class" style="vertical-align: middle !important;">
                                                <TableSortField :current="sortData.field" :no-sort="!field.isColumn" @onsort="sort"
                                                    :name="field.name" :field="field.field" class="fit-column" :title="field.name"/>
                                            </th>
                                            <th scope="col" v-else-if="field.selected" style="vertical-align: middle !important;">
                                                <TableSortField :current="sortData.field" :no-sort="!field.isColumn" @onsort="sort"
                                                    :class="field.class" :name="field.name" :field="field.field" :style="field.style" class="fit-column" :title="field.name"/>
                                            </th>
                                        </template>

                                        <!-- <th style="max-width:20px !important"></th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <!-- check name isempty -->
                                    <template v-for="(entry, index) in entries" :key="entry.id" >
                                        <tr class="master-row" :class="entry.id === this.productDetail ? 'backgroup__detail border__master' :''" :id="'row_' + (index + 1)">
                                        <!-- <tr v-for="entry in entries" :key="entry.id"  v-show="entry?.name && entry.name != '' ? entry.name : '' "></tr> -->
                                            <td style="max-width:40px !important;" id="chkOne">
                                                <input v-model="entry.checked" type="checkbox" @change.stop="countChecked(entry.id)" class="prdChecked"
                                                :id="entry.id" :name="entry.name" :value="entry.code" :checked="isExistInArrayChecked(entry, this.arrayChecked)"/>
                                            </td>
                                            <template v-for="field in fields" :key="field.field">                                                                                       
                                                <td class="ow-break-word py-0" v-if="field.selected && field.field!=='units' && field.field!=='branches' && field.field!=='categoryName'" 
                                                    style="vertical-align:middle !important;" :style="'height:'+ entry.name.length / 2 +'px;'">
                                                    <a :href="'#row_' + index" class="text-dark tagA h-100" @click="detailProduct(entry.id, entry)">
                                                        <div v-if="field.field==='code'" :style="field.style" class="py-2" v-text="entry.code" :title="entry.code"></div>
                                                        <div v-else-if="field.field==='name'" :style="field.style" class="py-2 cell-name" v-text="entry.name" :title="entry.name"></div>
                                                        <div v-else-if="field.field==='taxRate'" :style="field.style" class="pl-4">
                                                            <span class="mr-1" v-text="entry.taxRate" id="taxRate"></span>
                                                            <span>{{entry.taxRate !== '' && entry.taxRate !== null ? '%' : ''}}</span>
                                                        </div>
                                                        <div v-else-if="field.field==='type'" v-text="entry.typeName" :style="field.style"></div>                                                   
                                                        <div v-else-if="field.field==='description'" :style="field.style" v-text="entry.description" class="description" :title="entry.description"> </div>
                                                        <div v-else-if="field.field==='country'" :style="field.style" v-text="entry.country" class="description" :title="entry.country"> </div>                                                    
                                                        <template v-else-if="field.field==='weight' && entry.product_units">
                                                            <div v-text="entry.product_units[0]?.weight? parseFloat(entry.product_units[0]?.weight).toFixed(2) : ''" :style="field.style" :class="field.class">
                                                            </div>
                                                        </template>
                                                        <div v-else-if="field.field==='quantity_wait'" v-text="numberFloat(entry.quantity_wait)" :style="field.style" class="text-center"></div>
                                                        <div v-else-if="field.field==='quantity_confirmed'" v-text="numberFloat(entry.quantity_confirmed)" :style="field.style" class="text-center"></div>                                                              
                                                        <div v-else-if="field.field =='sendQuantity'" :style="field.style" class="text-center">
                                                            <template v-if="entry.get_send_quantity && entry.get_send_quantity.length">
                                                                <template v-for="(sendQuantity, index) in entry.get_send_quantity" :key="index">
                                                                    {{sendQuantity.total}}
                                                                </template>
                                                            </template>
                                                            <template v-else>
                                                                0
                                                            </template>
                                                        </div>
                                                        <div v-else-if="field.field==='supplier_ordered'" v-text="entry?.supplier_ordered ? entry.supplier_ordered : 0" :style="field.style" class="text-center"></div>                                                              
                                                        
                                                        <div v-else-if="field.field =='takeQuantity'" :style="field.style" class="text-center">
                                                            <template v-if="entry.get_receive_quantity && entry.get_receive_quantity.length">
                                                                <template v-for="(receiveQuantity, index) in entry.get_receive_quantity" :key="index">
                                                                    {{receiveQuantity.total}}
                                                                </template>
                                                            </template>
                                                            <template v-else>
                                                                0
                                                            </template>
                                                        </div>
                                                        <div v-else-if="field.field =='basePrice'" v-text="formatNumber(entry.basePrice)" :style="field.style" class="text-center"></div>
                                                        <div v-else-if="field.field =='inventory.quantity'" v-text="numberFloat(entry.inventories)" :style="field.style" class="text-center"></div>
                                                        <div v-else v-text="renderField(entry, field.field, field)" :title="renderField(entry, field.field, field)" :style="field.style" :class="field.class"></div>
                                                    </a>
                                                </td>
                                                <td class="ow-break-word" v-if="field.selected && field.field==='units'" style="vertical-align:middle !important;" :style="'min-width:110px'">
                                                    <div v-if="field.field==='units'" :style="field.style">
                                                        <select v-if="entry.product_units && entry.product_units?.length > 0" 
                                                            class="sltUnit pl-10" :id="'pU_' + entry.id"
                                                            @change="changeCurrentUnit(entry, entry.currentProductUnitId)"
                                                            v-model="entry.currentProductUnitId">
                                                            <option :key="pu.id" v-for="pu in entry.product_units" :value="pu.id" 
                                                                v-text="pu.unitName"></option>
                                                        </select>
                                                    </div>
                                                </td>
                                                <!-- Cột Kinh doanh tại kho -->
                                                <td class="ow-break-word" v-if="field.selected && field.field==='branches'" style="vertical-align:middle !important;" :style="'min-width:110px'">                                                    
                                                    <div v-if="field.field==='branches' && entry.branchName != null" class="pre-formatted" style="white-space: pre">
                                                        <select class="sltUnit" style="min-width: 160px;" v-if="entry.branchName && entry.branchName?.length > 0">
                                                            <option :key="n" v-for="n in entry.branchName" :value="n" v-text="n"></option>
                                                        </select>
                                                    </div>
                                                </td>
                                                <td class="ow-break-word" v-if="field.selected && field.field==='categoryName'" style="vertical-align:middle !important;" :style="'min-width:110px'">
                                                    <div v-if="field.field==='categoryName' && entry.categoryName != null" class="pre-formatted" style="white-space: pre">
                                                        <select class="sltUnit" style="min-width: 160px;" v-if="entry.categoryName && entry.categoryName?.length > 0">
                                                            <option :key="n" v-for="n in entry.categoryName" :value="n" v-text="n"></option>
                                                        </select>
                                                    </div>
                                                </td>                                                    
                                            </template>

                                            <!-- <td style="min-width:100px !important">
                                                <a class="btn px-1" :href="'/products/form?id=' + entry.id">
                                                    <i class="fa fa-edit" />
                                                </a>
                                                <a class="btn px-2" :href="'/products/form?id=' + entry.id+'&action=detail'">
                                                    <i class="fa fa-info-circle" />
                                                </a>
                                            </td> -->
                                        </tr>
                                        <!-- Hàng hóa không có thuộc tính -->
                                        <template v-if="!entry.is_product_has_attribute_count">
                                            <tr v-if="productDetail === entry.id" :class="[ entry.id === productDetail ? 'backgroup__detail border__header-detail border-top-0' : '']">
                                                <!-- <td></td> -->
                                                <td :colspan="fields.length+2" class="pb-0">
                                                    <div class="row detail-prd-row">
                                                        <div class="ml-4 mt-2" @click="showContent('info', entry)">
                                                            <span :class="[ headerActive === 'info' ? 'backgroup__detail-active' : '', 'head-detail']">
                                                                {{$t('message.ProductIndex.info')}}
                                                            </span>
                                                        </div>
                                                        <div class="mt-2" @click="showContent('stockCard', entry)">
                                                            <span :class="[ headerActive === 'stockCard' ? 'backgroup__detail-active' : '', 'head-detail']">
                                                                {{$t('message.ProductIndex.stockCard')}}
                                                            </span>
                                                        </div>
                                                        <div class="mt-2 ml-2" @click="showContent('maxInventory', entry)" v-if="entry.type != this.$constant.typeProductService && entry.type != this.$constant.typeProductCombo">
                                                            <span :class="[ headerActive === 'maxInventory' ? 'backgroup__detail-active' : '', 'head-detail']">
                                                                {{$t('message.ProductIndex.maxInventory')}}
                                                            </span>
                                                        </div>
                                                        <div class="mt-2" @click="showContent('inventory', entry)" v-if="entry.type != this.$constant.typeProductService && entry.type != this.$constant.typeProductCombo">
                                                            <span :class="[ headerActive === 'inventory' ? 'backgroup__detail-active' : '', 'head-detail']">
                                                                {{$t('message.ProductIndex.inventory')}}
                                                            </span>
                                                        </div>
                                                        <div class="mt-2" @click="showContent('batch', entry)" v-if="entry.type != this.$constant.typeProductService && entry.type != this.$constant.typeProductCombo && entry.isConsignment">
                                                            <span :class="[ headerActive === 'batch' ? 'backgroup__detail-active' : '', 'head-detail']">
                                                                {{$t('message.ProductIndex.batch')}}
                                                            </span>
                                                        </div>
                                                        <div class="mt-2" @click="showContent('combo', entry)" v-if="entry.type == this.$constant.typeProductCombo">
                                                            <span :class="[ headerActive === 'combo' ? 'backgroup__detail-active' : '', 'head-detail']">
                                                                {{$t('message.ProductIndex.combo')}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <!-- Chi tiết -->
                                            <tr class="detail-row bg-white border__body-detail" :id="'prd-' + entry.id" v-if="entry.id === this.productDetail && headerActive === 'info'">
                                                <th style="min-width:40px;" class="border-top-0">
                                                </th>
                                                <td :colspan="getDataChecked(this.fields)" class="border-top-0">
                                                    <div class="mt-2 pb-4">
                                                        <div class="row detail-prd-row">
                                                            <div class="col-12 mt-2"><b>{{entry.name}}</b></div>
                                                        </div>
                                                        <div class="row detail-prd-row mt-2">
                                                                <div class="img-container mt-4" v-if="entry.image?.length > 0">
                                                                    <div class="img-main" >
                                                                        <img :src="mainImage ? mainImage : entry.image[0]?.url" alt="" class="img-main-item" @error="imageUrlAlt">
                                                                    </div>
                                                                    <div class="img-list">
                                                                        <div class ="img-item mb-1" v-for="img in entry.image" :key="img.id" @click="onChooseImage(img?.url)">
                                                                            <img :src="img?.url" alt="" class="detail-row-img" @error="imageUrlAlt">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div v-else class="img-container mt-4">
                                                                    <div class="img-main">
                                                                        <img src="../../../public/img/default-product.png" alt="" class="img-main-item">
                                                                    </div>
                                                                    <div class="img-list">
                                                                        <div class ="img-item">
                                                                            <img src="../../../public/img/default-product.png" alt="" class="detail-row-img">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            <div class="col-4">
                                                                <div class="row mx-1">
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-4 px-0">
                                                                                {{$t('message.ProductIndex.code')}}:
                                                                            </div>
                                                                            <div class="col-8">
                                                                                {{entry.code}}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-4 px-0">
                                                                                {{$t('message.ProductIndex.categories')}}:
                                                                            </div>
                                                                            <div class="col-8">                                                                            
                                                                                <template :key="n" v-for="(n,index) in entry.categoryName">                                                                                
                                                                                    <span v-if="index === entry.categoryName?.length - 1 " v-text="n"></span>
                                                                                    <span v-else v-text="n + ', '"></span>
                                                                                </template>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-4 px-0">
                                                                                {{$t('message.ProductIndex.product_type')}}:
                                                                            </div>
                                                                            <div class="col-8">
                                                                                <div v-text="entry.typeName"></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-4 px-0">
                                                                                {{$t('message.ProductIndex.maxInventory')}}:
                                                                            </div>
                                                                            <div class="col-8">
                                                                                <!-- <div v-text="entry.typeName"></div> -->
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-4 px-0">
                                                                                {{$t('message.ProductIndex.price')}}:
                                                                            </div>
                                                                            <div class="col-8" v-text="formatNumber(entry.basePrice)"></div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-4 px-0">
                                                                                {{$t('message.ProductIndex.weight')}}:
                                                                            </div>
                                                                            <div class="col-8">
                                                                                <!-- <div v-text="entry.typeName"></div> -->
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-4 px-0">
                                                                                {{$t('message.ProductIndex.location')}}:
                                                                            </div>
                                                                            <div class="col-8">
                                                                                <!-- <div v-text="entry.typeName"></div> -->
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4">
                                                                <div class="row">
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-4 px-0">
                                                                                {{$t('message.ProductIndex.manufacturer')}}:
                                                                            </div>
                                                                            <div class="col-8">
                                                                                {{entry.manufacturer}}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-4 px-0">
                                                                                {{$t('message.ProductIndex.country')}}:
                                                                            </div>
                                                                            <div class="col-8 country">
                                                                                {{entry.country}}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-4 px-0">
                                                                                {{$t('message.ProductIndex.unit')}}:
                                                                            </div>
                                                                            <div class="col-8">
                                                                                {{entry.unit}}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-4 px-0">
                                                                                {{$t('message.ProductIndex.description')}}:
                                                                            </div>
                                                                            <div class="col-8">
                                                                                {{entry.description}}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-4 px-0">
                                                                                {{$t('message.ProductIndex.note')}}:
                                                                            </div>
                                                                            <div class="col-8">
                                                                                <!-- {{entry.description}} -->
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-4 px-0">
                                                                                {{$t('message.ProductIndex.supplier')}}:
                                                                            </div>
                                                                            <div class="col-8">
                                                                                <!-- {{entry.description}} -->
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row detail-prd-row flex-end mt-4 pr-5">
                                                                <AppPermission :permission="this.$permissions.products.update">
                                                                    <button type="button" class="btn btn-save mx-1" @click="showModalProductsEditById(entry.id)">
                                                                        <i class="fa fa-check"></i>
                                                                        {{$t('message.ProductIndex.button.update')}}
                                                                    </button>
                                                                </AppPermission>
                                                            <button type="button" class="btn btn-save mx-1" @click="copy(entry.id)">
                                                                    <i class="fa fa-copy"></i>
                                                                    {{$t('message.ProductIndex.button.copy')}}
                                                            </button>
                                                            <!-- <AppPermission :permission="'Products.export'"> -->
                                                                <button type="button" class="btn btn-secondary text-white mx-1" @click="showModalProductsBarcode(entry)">
                                                                    <i class="fa fa-barcode"></i>
                                                                    {{$t('message.ProductIndex.button.print')}}
                                                                </button> 
                                                            <!-- </AppPermission> -->
                                                            <AppPermission :permission="this.$permissions.products.inActive">                                                            
                                                                <button type="button" class="btn btn-danger mx-1" @click="showModalProductStopWorking(entry.id, entry.code, 1)" v-if="setStatusByBranch(entry, entry.id) == this.$constant.productActive">
                                                                    <i class="fa fa-lock"></i>
                                                                    {{$t('message.ProductIndex.button.in_active')}}
                                                                </button>                                                                                                    
                                                                <button v-else type="button" class="btn btn-primary mx-1" @click="showModalProductStopWorking(entry.id, entry.code, 0)">
                                                                    <i class="fa fa-unlock-alt" aria-hidden="true"></i>
                                                                    {{$t('message.ProductIndex.button.active')}}
                                                                </button>
                                                            </AppPermission>
                                                            
                                                            <AppPermission :permission="this.$permissions.products.remove">
                                                                <button type="button" class="btn btn-danger mx-1" @click="showModalProductRemove(entry)">
                                                                    <i class="fa fa-trash-alt"></i>
                                                                    {{$t('message.ProductIndex.button.remove')}}
                                                                </button>
                                                            </AppPermission>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="detail-row bg-white border__body-detail" v-if="productDetail === entry.id && headerActive === 'stockCard'">
                                                <td :colspan="fields.length + 1" class="border-top-0">
                                                    <div class="row detail-prd-row mx-0 mt-3 pr-4 mb-4">
                                                        <div class="col-12 p-0">
                                                            <table class="w-100">
                                                                <thead>
                                                                    <tr class="backgroup__detail table-header border-1">
                                                                        <th scope="col" class="pl-4 cell-code">
                                                                            {{$t('message.ProductIndex.table.stockCard.code')}}
                                                                        </th>
                                                                        <th scope="col" class="pl-4 cell-auto text-center">
                                                                            {{$t('message.ProductIndex.table.stockCard.time')}}
                                                                        </th>
                                                                        <th scope="col" class="pl-4 cell-release-coupon text-center">
                                                                            {{$t('message.ProductIndex.table.stockCard.type')}}
                                                                        </th>
                                                                        <th scope="col" class="pl-4 cell-auto">
                                                                            <div class="d-flex justify-content-center p-0 m-0">
                                                                                {{$t('message.ProductIndex.table.stockCard.price')}}
                                                                                <div class="container-circle tooltip-left-center">
                                                                                    <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
                                                                                    <div class="content-circle font-weight-normal">
                                                                                        <span>
                                                                                            {{$t('message.ProductIndex.table.stockCard.price_tooltip_1')}}<br>
                                                                                            {{$t('message.ProductIndex.table.stockCard.price_tooltip_2')}}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </th>
                                                                        <th scope="col" class="pl-4 cell-auto text-center">
                                                                            {{$t('message.ProductIndex.quantity')}}
                                                                        </th>
                                                                        <th scope="col" class="pl-4 cell-auto text-center">
                                                                            {{$t('message.ProductIndex.table.stockCard.last_inventory')}}
                                                                        </th>
                                                                        <th scope="col" class="pl-4 cell-auto">
                                                                            {{$t('message.ProductIndex.table.stockCard.transporter')}}
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <template v-if="this.defaultStockCard.filter?.length > 0">
                                                                        <template v-for="(stock, i) in this.defaultStockCard.filter" :key="i">
                                                                            <template v-if="i >= (this.paginateDetailStockCard.currentPage - 1)*10 && i < this.paginateDetailStockCard.currentPage*10">
                                                                                <tr class="table-header border">
                                                                                    <th scope="col" class="pl-4 cell-code text-primary" @click="showDetailStockCard(stock.id, stock.type_eng)" v-text="stock.code"></th>
                                                                                    <th scope="col" class="pl-4 cell-auto text-center font-weight-normal" v-text="(stock.date)"></th>
                                                                                    <th scope="col" class="pl-4 cell-release-coupon text-center font-weight-normal" v-text="stock.type"></th>
                                                                                    <th scope="col" class="pl-4 cell-auto text-center font-weight-normal" v-text="n(stock.price)"></th>
                                                                                    <th scope="col" class="pl-4 cell-auto text-center font-weight-normal" v-text="numberFloat(stock.quantity)"></th>
                                                                                    <th scope="col" class="pl-4 cell-auto text-center font-weight-normal" v-text="n(stock.lastInventory)"></th>
                                                                                    <th scope="col" class="pl-4 cell-auto font-weight-normal" v-text="stock.partner"></th>
                                                                                </tr>
                                                                            </template>
                                                                        </template>
                                                                    </template>
                                                                </tbody>
                                                            </table>
                                                            <Pagination :value="paginateDetailStockCard" :record="10" v-if="this.paginateDetailStockCard.totalRecord >= 10"/>
                                                            <div class="no-data border border-top-0" v-if="!this.defaultStockCard.filter || this.defaultStockCard.filter?.length == 0">
                                                                <span>{{ $t('message.common.notFound') }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="detail-row bg-white border__body-detail" v-if="productDetail === entry.id && headerActive === 'maxInventory'">
                                                <td :colspan="fields.length + 1" class="border-top-0">
                                                    <div class="row detail-prd-row mx-0 mt-3 pr-4 mb-4">
                                                        <div class="col-12 p-0">
                                                            <table class="w-100">
                                                                <thead>
                                                                    <tr class="backgroup__detail table-header border-1">
                                                                        <th scope="col" class="pl-4 cell-code">
                                                                            {{$t('message.ProductIndex.branch')}}
                                                                        </th>
                                                                        <th scope="col" class="pl-4 cell-auto text-center">
                                                                            {{$t('message.ProductIndex.table.maxInventory.on_show_quantity')}}
                                                                        </th>
                                                                        <th scope="col" class="pl-4 cell-release-coupon text-center">
                                                                            {{$t('message.ProductIndex.table.maxInventory.min_inventory')}}
                                                                        </th>
                                                                        <th scope="col" class="pl-4 cell-auto text-center">
                                                                            {{$t('message.ProductIndex.table.maxInventory.max_inventory')}}
                                                                        </th>
                                                                        <th scope="col" class="pl-4 cell-auto"></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr class="table-header-search border">
                                                                        <th scope="col" class="pl-4 cell-code position-relative">
                                                                            <input type="text" v-model="this.defaultMaxInventory.search" :placeholder="$t('message.ProductIndex.placeholder.branch')" class="form-control input-custom bg-transparent pr-4" autocomplete="off"  @input="onInputFilterMaxInventory">
                                                                            <i class="fa fa-search mr-2 text-gray icon-search_input"></i>
                                                                        </th>
                                                                        <th scope="col" class="pl-4 cell-auto text-center"></th>
                                                                        <th scope="col" class="pl-4 cell-release-coupon text-center"></th>
                                                                        <th scope="col" class="pl-4 cell-auto text-center"></th>
                                                                        <th scope="col" class="pl-4 cell-auto"></th>
                                                                    </tr>
                                                                    <template v-if="this.defaultMaxInventory.filter?.length > 0">
                                                                        <template v-for="(inventory, i) in this.defaultMaxInventory.filter" :key="i">
                                                                            <template v-if="i >= (this.paginateDetailMaxInventory.currentPage - 1)*10 && i < this.paginateDetailMaxInventory.currentPage*10">
                                                                                <tr class="table-header border">
                                                                                    <th scope="col" class="pl-4 cell-code position-relative font-weight-normal" v-text="inventory.branch ? inventory.branch.name : null"></th>
                                                                                    <th scope="col" class="pl-4 cell-auto text-center font-weight-normal">
                                                                                        <template v-if="this.inventoryEditId == inventory.id">
                                                                                            <InputNumberFormat v-model="inventory.onShowRoomQuantityDraft" :min="0" class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true" :inputMarginAuto="true"></InputNumberFormat>
                                                                                        </template>
                                                                                        <template v-else>
                                                                                            <span v-text="n(inventory.onShowRoomQuantity)"></span>
                                                                                        </template>
                                                                                    </th>
                                                                                    <th scope="col" class="pl-4 cell-release-coupon text-center font-weight-normal">
                                                                                        <template v-if="this.inventoryEditId == inventory.id">
                                                                                            <InputNumberFormat v-model="inventory.minimalQuantityDraft" :min="0" class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true" :inputMarginAuto="true"></InputNumberFormat>
                                                                                        </template>
                                                                                        <template v-else>
                                                                                            <span v-text="n(inventory.minimalQuantity)"></span>
                                                                                        </template>
                                                                                    </th>
                                                                                    <th scope="col" class="pl-4 cell-auto text-center font-weight-normal">
                                                                                        <template v-if="this.inventoryEditId == inventory.id">
                                                                                            <InputNumberFormat v-model="inventory.maximumQuantityDraft" :min="0" class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true" :inputMarginAuto="true"></InputNumberFormat>
                                                                                        </template>
                                                                                        <template v-else>
                                                                                            <span v-text="n(inventory.maximumQuantity)"></span>
                                                                                        </template>
                                                                                    </th>
                                                                                    <th scope="col" class="pl-4 cell-auto">
                                                                                        <div class="flex-end">
                                                                                            <template v-if="this.inventoryEditId == inventory.id">
                                                                                                <AppPermission :permission="[this.$permissions.products.create ?? '', this.$permissions.products.update ?? '']">
                                                                                                    <button class="btn btn-success py-1 mr-2" @click="saveMaxInventory(inventory, entry)">
                                                                                                        {{$t('message.button.save')}}
                                                                                                    </button>                                                                                                                                                                  
                                                                                                </AppPermission>
                                                                                                <button class="btn btn-default py-1 mr-2" @click="changeInventoryEditId(null, inventory)">
                                                                                                    {{$t('message.button.skip')}}
                                                                                                </button>
                                                                                            </template>
                                                                                            <template v-else>
                                                                                                <AppPermission :permission="[this.$permissions.products.create ?? '', this.$permissions.products.update ?? '']">
                                                                                                <button class="btn btn-save py-1 mr-2" @click="changeInventoryEditId(inventory.id, inventory)">
                                                                                                    {{$t('message.ProductIndex.button.update')}}
                                                                                                </button>
                                                                                                </AppPermission>
                                                                                            </template>
                                                                                        </div>
                                                                                    </th>
                                                                                </tr>
                                                                            </template>
                                                                        </template>
                                                                    </template>
                                                                </tbody>
                                                            </table>
                                                            <Pagination :value="paginateDetailMaxInventory" :record="10" v-if="this.paginateDetailMaxInventory.totalRecord >= 10"/>
                                                            <div class="no-data border border-top-0" v-if="!this.defaultMaxInventory.filter || this.defaultMaxInventory.filter?.length == 0">
                                                                <span>{{ $t('message.common.notFound') }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="detail-row bg-white border__body-detail" v-if="productDetail === entry.id && headerActive === 'inventory'">
                                                <td :colspan="fields.length + 1" class="border-top-0">
                                                    <div class="row detail-prd-row mx-0 mt-3 pr-4 mb-4">
                                                        <div class="col-12 p-0">
                                                            <table class="w-100">
                                                                <thead>
                                                                    <tr class="backgroup__detail table-header border-1">
                                                                        <th scope="col" class="pl-4 cell-code">
                                                                            {{$t('message.ProductIndex.branch')}}
                                                                        </th>
                                                                        <th scope="col" class="pl-4 cell-auto">
                                                                            {{$t('message.ProductIndex.inventory')}}
                                                                        </th>
                                                                        <th scope="col" class="pl-4 cell-auto">
                                                                            {{$t('message.ProductIndex.table.inventory.quantity_order')}}
                                                                        </th>
                                                                        <th scope="col" class="pl-4 cell-release-coupon">
                                                                            {{$t('message.ProductIndex.table.inventory.customer_order')}}
                                                                        </th>
                                                                        <th scope="col" class="pl-4 cell-auto">
                                                                            {{$t('message.ProductIndex.table.inventory.expected_out_of_stock')}}
                                                                        </th>
                                                                        <th scope="col" class="pl-4 cell-status">
                                                                            {{$t('message.ProductIndex.status')}}
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr class="table-header-search border">
                                                                        <th scope="col" class="pl-4 cell-code-coupon position-relative">
                                                                            <input type="text" v-model="this.defaultProductBranchMeta.search" :placeholder="$t('message.ProductIndex.placeholder.branch')" class="form-control input-custom bg-transparent pr-4" autocomplete="off" @input="onInputFilterBranch">
                                                                            <i class="fa fa-search mr-2 text-gray icon-search_input"></i>
                                                                        </th>
                                                                        <th scope="col" class="pl-4 cell-auto"></th>
                                                                        <th scope="col" class="pl-4 cell-auto"></th>
                                                                        <th scope="col" class="pl-4 cell-release-coupon"></th>
                                                                        <th scope="col" class="pl-4 cell-auto"></th>
                                                                        <th scope="col" class="pl-4 cell-auto"></th>
                                                                    </tr>
                                                                    <template v-if="this.defaultProductBranchMeta.filter?.length > 0">
                                                                        <template v-for="(inventory, i) in this.defaultProductBranchMeta.filter" :key="i">
                                                                            <template  v-if="i >= (this.paginateDetailInventory.currentPage - 1)*10 && i < this.paginateDetailInventory.currentPage*10">
                                                                                <tr class="table-header border">
                                                                                    <th scope="col" class="pl-4 cell-code font-weight-normal" v-text="inventory.branch?.name"></th>
                                                                                    <th scope="col" class="pl-4 cell-auto font-weight-normal" v-text="numberFloat(inventory.inventories ? inventory.inventories : 0)"></th>
                                                                                    <th scope="col" class="pl-4 cell-auto font-weight-normal" v-text="numberFloat(inventory.quantitySupplier ? inventory.quantitySupplier : 0)"></th>
                                                                                    <th scope="col" class="pl-4 cell-release-coupon font-weight-normal" v-text="numberFloat(inventory.quantityOrder ? inventory.quantityOrder : 0)"></th>
                                                                                    <th scope="col" class="pl-4 cell-auto font-weight-normal"></th>
                                                                                    <th scope="col" class="pl-4 cell-status font-weight-normal" v-text="status(inventory.status)"></th>
                                                                                </tr>
                                                                            </template>
                                                                        </template>
                                                                    </template>
                                                                </tbody>
                                                            </table>
                                                            <Pagination :value="paginateDetailInventory" :record="10" v-if="this.paginateDetailInventory.totalRecord >= 10"/>
                                                            <div class="no-data border border-top-0" v-if="!this.defaultProductBranchMeta.filter || this.defaultProductBranchMeta.filter?.length == 0">
                                                                <span>{{ $t('message.common.notFound') }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="detail-row bg-white border__body-detail" v-if="productDetail === entry.id && headerActive === 'batch'">
                                                <td :colspan="fields.length + 1" class="border-top-0">
                                                    <div class="row detail-prd-row mx-0 mt-3 pr-4">
                                                        <div class="col-12 p-0">
                                                            <table class="w-100">
                                                                <thead>
                                                                    <tr class="backgroup__detail table-header border-1">
                                                                        <th scope="col" class="pl-4 cell-code">
                                                                            {{$t('message.ProductIndex.table.batch.batch_number')}}
                                                                        </th>
                                                                        <th scope="col" class="pl-4 cell-auto text-center">
                                                                            {{$t('message.ProductIndex.table.batch.expired')}}
                                                                        </th>
                                                                        <th scope="col" class="pl-4 cell-auto text-center">
                                                                            {{$t('message.ProductIndex.quantity')}}
                                                                        </th>
                                                                        <th scope="col" class="pl-4 cell-status">
                                                                            {{$t('message.ProductIndex.status')}}
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr class="table-header-search border">
                                                                        <th scope="col" class="pl-4 cell-code position-relative">
                                                                            <input type="text" v-model="this.defaultProductBatch.search" :placeholder="$t('message.ProductIndex.placeholder.batch')" class="form-control input-custom bg-transparent pr-4" autocomplete="off" @input="onInputFilterBatch">
                                                                            <i class="fa fa-search mr-2 text-gray icon-search_input"></i>
                                                                        </th>
                                                                        <th scope="col" class="pl-4 cell-auto text-center"></th>
                                                                        <th scope="col" class="pl-4 cell-auto text-center" v-text="n(entry.totalQuantityConsignments)"></th>
                                                                        <th scope="col" class="pl-4 cell-status">
                                                                            <SelectSearch :modelValue="this.defaultProductBatch.status" :readonly="true" :defaultOption="this.$constant.optionStatusConsignment" @update:modelValue="doFilterSearchBatchList" :label="'statusConsignment'"/>
                                                                        </th>
                                                                    </tr>
                                                                    <template v-if="this.defaultProductBatch.filter?.length > 0">
                                                                        <template v-for="(consignment, i) in this.defaultProductBatch.filter" :key="i">
                                                                            <template v-if="i >= (this.paginateDetailBatch.currentPage - 1)*10 && i < this.paginateDetailBatch.currentPage*10">
                                                                                <tr class="table-header border">
                                                                                    <th scope="col" class="pl-4 cell-code font-weight-normal"><span class="consignment_name mr-2 text-white" v-text="consignment.name"></span></th>
                                                                                    <th scope="col" class="pl-4 cell-auto font-weight-normal text-center" v-text="d(consignment.expried)"></th>
                                                                                    <th scope="col" class="pl-4 cell-auto font-weight-normal text-center" v-text="numberFloat(consignment.quantity)"></th>
                                                                                    <th scope="col" class="pl-4 cell-status font-weight-normal" v-text="consignment.status"></th>
                                                                                </tr>
                                                                            </template>
                                                                        </template>
                                                                    </template>
                                                                </tbody>
                                                            </table>
                                                            <Pagination :value="paginateDetailBatch" :record="10" v-if="this.paginateDetailBatch.totalRecord >= 10"/>
                                                            <div class="no-data border border-top-0" v-if="!this.defaultProductBatch.filter || this.defaultProductBatch.filter?.length == 0">
                                                                <span>{{ $t('message.common.notFound') }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row detail-prd-row mx-0 my-3 pr-4">
                                                        <div class="col-12 flex-end pr-0">
                                                                <AppPermission :permission="this.$permissions.products.export">
                                                                    <button type="button" class="btn btn-secondary text-white mx-1" @click="exportDetailConsignment(this.defaultProductBatch.filter)">
                                                                        <i class="fa fa-file-export" aria-hidden="true"></i>
                                                                        {{$t('message.ProductIndex.button.export_file')}}
                                                                    </button>
                                                                </AppPermission>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="detail-row bg-white border__body-detail" v-if="productDetail === entry.id && headerActive === 'combo'">
                                                <td :colspan="fields.length + 1" class="border-top-0">
                                                    <div class="row detail-prd-row mx-0 mt-3 pr-4">
                                                        <div class="col-12 p-0">
                                                            <table class="w-100">
                                                                <thead>
                                                                    <tr class="backgroup__detail table-header border-1">
                                                                        <th scope="col" class="pl-4 cell-code">
                                                                            {{$t('message.ProductIndex.table.combo.STT')}}
                                                                        </th>
                                                                        <th scope="col" class="pl-4 cell-auto text-center">
                                                                            {{$t('message.ProductIndex.code')}}
                                                                        </th>
                                                                        <th scope="col" class="pl-4 cell-auto text-center">
                                                                            {{$t('message.ProductIndex.table.combo.component_row_name')}}
                                                                        </th>
                                                                        <th scope="col" class="pl-4 cell-status">
                                                                            {{$t('message.ProductIndex.quantity')}}
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <template v-if="entry.combo_products?.length > 0">
                                                                        <tr v-for="(combo, i) in entry.combo_products" :key="i" class="table-header border">
                                                                            <th scope="col" class="pl-4 cell-code font-weight-normal" v-text="n(i + 1)"></th>
                                                                            <th scope="col" class="pl-4 cell-auto font-weight-normal text-center" v-text="combo.code"></th>
                                                                            <th scope="col" class="pl-4 cell-auto font-weight-normal text-center" v-text="combo.name"></th>
                                                                            <th scope="col" class="pl-4 cell-status font-weight-normal" v-text="combo.quantity"></th>
                                                                        </tr>
                                                                    </template>
                                                                </tbody>
                                                            </table>
                                                            <div class="no-data border border-top-0" v-if="entry.combo_products?.length == 0">
                                                                <span>{{ $t('message.common.notFound') }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row detail-prd-row mx-0 my-3 pr-4">
                                                        <div class="col-12 flex-end pr-0">
                                                            <AppPermission :permission="'Products.export'">
                                                                <button type="button" class="btn btn-secondary text-white mx-1" @click="exportCombo(entry.id)">
                                                                    <i class="fa fa-file-export" aria-hidden="true"></i>
                                                                    {{$t('message.ProductIndex.button.export_file')}}
                                                                </button>
                                                            </AppPermission>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="detail-row bg-white border__footer-detail" v-if="productDetail === entry.id"></tr>
                                        </template>
                                        <template v-else>
                                            <tr class="detail-row bg-white border__body-detail" :id="'prd-' + entry.id" v-if="entry.id === this.productDetail">
                                                <th style="min-width:40px;" class="border-top-0">
                                                </th>
                                                <th :colspan="this.fields.length" class="border-top-0">
                                                    <table class="table table-index table-striped">
                                                        <tbody class="font-weight-normal">
                                                            <template v-for="(attr, index_attr) in entry.attributes" :key="index_attr">
                                                                <tr :class="attr.id === this.productAttrActive ? 'backgroup__detail border__master' :''">
                                                                    <td style="min-width:40px !important;">
                                                                        <input v-model="attr.checked" type="checkbox" :id="entry.id + '_' + attr.id"/>
                                                                    </td>
                                                                    <template v-for="field in fields" :key="field.field">                                                                                       
                                                                        <td class="ow-break-word py-0" v-if="field.selected && field.field != 'code'" 
                                                                            style="vertical-align:middle !important;" :style="'min-width:110px'" 
                                                                            @click="detailProductAttr(attr.id)">
                                                                            <div v-if="field.field==='name'" style="min-width: 280px !important;" class="cell-name">                                                            
                                                                                <span class="mr-1" v-text="entry.name + (attr.name ? ' - ' +  attr.name : ' - ' + attr.unitName)"></span>
                                                                            </div>
                                                                            <div v-else-if="field.field==='units'" class="text-left">                                                            
                                                                                <span class="mr-1" v-text="attr.unitName"></span>
                                                                            </div>
                                                                            <div v-else-if="field.field==='basePrice'" class="text-right">                                                            
                                                                                <span class="mr-1" v-text="n(attr.price)"></span>
                                                                            </div>
                                                                            <div v-else-if="field.field==='categoryName' && entry.categoryName != null" class="pre-formatted" style="white-space: pre">
                                                                                <select class="sltUnit" v-if="entry.categoryName && entry.categoryName?.length > 0">
                                                                                    <option :key="n" v-for="n in entry.categoryName" :value="n" v-text="n"></option>
                                                                                </select>
                                                                            </div>
                                                                            <div v-else-if="field.field==='type'" v-text="entry.typeName" :style="field.style"></div>                                                   
                                                                            <div v-else-if="field.field==='description'" :style="field.style" class="description"> </div>
                                                                            <div v-else-if="field.field==='country'" :style="field.style" class="description"> </div>                                                    
                                                                            <div v-else-if="field.field==='quantity_wait'" v-text="numberFloat(attr.quantityOrderWait)" :style="field.style" class="text-center"></div>
                                                                            <div v-else-if="field.field==='quantity_confirmed'" v-text="numberFloat(attr.quantityOrderConfirm)" :style="field.style" class="text-center"></div>                                                              
                                                                            <div v-else v-text="renderField(attr, field.field, field)" :title="renderField(attr, field.field, field)" :style="field.style" :class="field.class"></div>
                                                                        </td>
                                                                    </template>
                                                                </tr>

                                                                <tr v-if="productAttrActive === attr.id" :class="[ attr.id === productAttrActive ? 'backgroup__detail border__header-detail border-top-0' : '']">
                                                                    <td :colspan="fields.length + 1" class="pb-0">
                                                                        <div class="row detail-prd-row">
                                                                            <div class="ml-4 mt-2" @click="showContent('info', entry, attr)">
                                                                                <span :class="[ headerActive === 'info' ? 'backgroup__detail-active' : '', 'head-detail']">
                                                                                    {{$t('message.ProductIndex.info')}}
                                                                                </span>
                                                                            </div>
                                                                            <div class="mt-2" @click="showContent('stockCard', entry, attr)">
                                                                                <span :class="[ headerActive === 'stockCard' ? 'backgroup__detail-active' : '', 'head-detail']">
                                                                                    {{$t('message.ProductIndex.stockCard')}}
                                                                                </span>
                                                                            </div>
                                                                            <div class="mt-2" @click="showContent('maxInventory', entry, attr)" v-if="entry.type != this.$constant.typeProductService && entry.type != this.$constant.typeProductCombo">
                                                                                <span :class="[ headerActive === 'maxInventory' ? 'backgroup__detail-active' : '', 'head-detail']">
                                                                                    {{$t('message.ProductIndex.maxInventory')}}
                                                                                </span>
                                                                            </div>
                                                                            <div class="mt-2" @click="showContent('inventory', entry, attr)" v-if="entry.type != this.$constant.typeProductService && entry.type != this.$constant.typeProductCombo">
                                                                                <span :class="[ headerActive === 'inventory' ? 'backgroup__detail-active' : '', 'head-detail']">
                                                                                    {{$t('message.ProductIndex.inventory')}}
                                                                                </span>
                                                                            </div>
                                                                            <div class="mt-2" @click="showContent('batch', entry, attr)" v-if="entry.type != this.$constant.typeProductService && entry.type != this.$constant.typeProductCombo && entry.isConsignment">
                                                                                <span :class="[ headerActive === 'batch' ? 'backgroup__detail-active' : '', 'head-detail']">
                                                                                    {{$t('message.ProductIndex.batch')}}
                                                                                </span>
                                                                            </div>
                                                                            <div class="mt-2" @click="showContent('combo', entry, attr)" v-if="entry.type == this.$constant.typeProductCombo">
                                                                                <span :class="[ headerActive === 'combo' ? 'backgroup__detail-active' : '', 'head-detail']">
                                                                                    {{$t('message.ProductIndex.combo')}}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr class="detail-row bg-white border__body-detail" :id="'prd-' + attr.id" v-if="attr.id === this.productAttrActive && headerActive === 'info'">
                                                                    <td :colspan="this.fields.length" class="border-top-0">
                                                                        <div class="mt-2 pb-4">
                                                                            <div class="row detail-prd-row m-0 p-0">
                                                                                <div class="col-12 mt-2"><b>{{entry.name + ' - ' + (attr.name ? attr.name : '')}}</b></div>
                                                                            </div>
                                                                            <div class="row detail-prd-row m-0 p-0">
                                                                                    <div class="img-container mt-4" v-if="entry.image?.length > 0">
                                                                                        <div class="img-main" >
                                                                                            <img :src="mainImage ? mainImage : entry.image[0]?.url" alt="" class="img-main-item" @error="imageUrlAlt">
                                                                                        </div>
                                                                                        <div class="img-list">
                                                                                            <div class ="img-item mb-1" v-for="img in entry.image" :key="img.id" @click="onChooseImage(img?.url)">
                                                                                                <img :src="img?.url" alt="" class="detail-row-img" @error="imageUrlAlt">
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div v-else class="img-container mt-4">
                                                                                        <div class="img-main">
                                                                                            <img src="../../../public/img/default-product.png" alt="" class="img-main-item">
                                                                                        </div>
                                                                                        <div class="img-list">
                                                                                            <div class ="img-item">
                                                                                                <img src="../../../public/img/default-product.png" alt="" class="detail-row-img">
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                <div class="col-4">
                                                                                    <div class="row mx-1">
                                                                                        <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                                            <div class="row">
                                                                                                <div class="col-5 px-0">
                                                                                                    {{$t('message.ProductIndex.code')}}:
                                                                                                </div>
                                                                                                <div class="col-7">
                                                                                                    {{entry.code}}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                                            <div class="row">
                                                                                                <div class="col-5 px-0">
                                                                                                    {{$t('message.ProductIndex.categories')}}:
                                                                                                </div>
                                                                                                <div class="col-7">                                                                            
                                                                                                    <template :key="n" v-for="(n,index) in entry.categoryName">                                                                                
                                                                                                        <span v-if="index === entry.categoryName?.length - 1 " v-text="n"></span>
                                                                                                        <span v-else v-text="n + ', '"></span>
                                                                                                    </template>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                                            <div class="row">
                                                                                                <div class="col-5 px-0">
                                                                                                    {{$t('message.ProductIndex.product_type')}}:
                                                                                                </div>
                                                                                                <div class="col-7">
                                                                                                    <div v-text="entry.typeName"></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                                            <div class="row">
                                                                                                <div class="col-5 px-0">
                                                                                                    {{$t('message.ProductIndex.maxInventory')}}:
                                                                                                </div>
                                                                                                <div class="col-7">
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                                            <div class="row">
                                                                                                <div class="col-5 px-0">
                                                                                                    {{$t('message.ProductIndex.price')}}:
                                                                                                </div>
                                                                                                <div class="col-7" v-text="n(attr.price)"></div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                                            <div class="row">
                                                                                                <div class="col-5 px-0">
                                                                                                    {{$t('message.ProductIndex.weight')}}:
                                                                                                </div>
                                                                                                <div class="col-7">
                                                                                                    <div v-text="n(entry.weight)"></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                                            <div class="row">
                                                                                                <div class="col-5 px-0">
                                                                                                    {{$t('message.ProductIndex.location')}}:
                                                                                                </div>
                                                                                                <div class="col-7">
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-4">
                                                                                    <div class="row">
                                                                                        <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                                            <div class="row">
                                                                                                <div class="col-5 px-0">
                                                                                                    {{$t('message.ProductIndex.manufacturer')}}:
                                                                                                </div>
                                                                                                <div class="col-7">
                                                                                                    {{entry.manufacturer}}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                                            <div class="row">
                                                                                                <div class="col-5 px-0">
                                                                                                    {{$t('message.ProductIndex.country')}}:
                                                                                                </div>
                                                                                                <div class="col-7 country">
                                                                                                    {{entry.country}}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                                            <div class="row">
                                                                                                <div class="col-5 px-0">
                                                                                                    {{$t('message.ProductIndex.unit')}}:
                                                                                                </div>
                                                                                                <div class="col-7">
                                                                                                    {{entry.unit}}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                                            <div class="row">
                                                                                                <div class="col-5 px-0">
                                                                                                    {{$t('message.ProductIndex.description')}}:
                                                                                                </div>
                                                                                                <div class="col-7">
                                                                                                    {{entry.description}}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                                            <div class="row">
                                                                                                <div class="col-5 px-0">
                                                                                                    {{$t('message.ProductIndex.note')}}:
                                                                                                </div>
                                                                                                <div class="col-7">
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                                            <div class="row">
                                                                                                <div class="col-5 px-0">
                                                                                                    {{$t('message.ProductIndex.supplier')}}:
                                                                                                </div>
                                                                                                <div class="col-7">
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr class="detail-row bg-white border__body-detail" v-if="productAttrActive === attr.id && headerActive === 'stockCard'">
                                                                    <td :colspan="fields.length + 1" class="border-top-0">
                                                                        <div class="row detail-prd-row_detail mx-0 mt-3 pr-4 mb-4">
                                                                            <div class="col-12 p-0">
                                                                                <table class="w-100">
                                                                                    <thead>
                                                                                        <tr class="backgroup__detail table-header border-1">
                                                                                            <th scope="col" class="pl-4 cell-code">
                                                                                                {{$t('message.ProductIndex.table.stockCard.code')}}
                                                                                            </th>
                                                                                            <th scope="col" class="pl-4 cell-auto text-center">
                                                                                                {{$t('message.ProductIndex.table.stockCard.time')}}
                                                                                            </th>
                                                                                            <th scope="col" class="pl-4 cell-release-coupon text-center">
                                                                                                {{$t('message.ProductIndex.table.stockCard.type')}}
                                                                                            </th>
                                                                                            <th scope="col" class="pl-4 cell-auto">
                                                                                                <div class="d-flex justify-content-center p-0 m-0">
                                                                                                    {{$t('message.ProductIndex.table.stockCard.price')}}
                                                                                                    <div class="container-circle tooltip-left-center">
                                                                                                        <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
                                                                                                        <div class="content-circle font-weight-normal">
                                                                                                            <span>
                                                                                                                {{$t('message.ProductIndex.table.stockCard.price_tooltip_1')}}<br>
                                                                                                                {{$t('message.ProductIndex.table.stockCard.price_tooltip_2')}}
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </th>
                                                                                            <th scope="col" class="pl-4 cell-auto text-center">
                                                                                                {{$t('message.ProductIndex.quantity')}}
                                                                                            </th>
                                                                                            <th scope="col" class="pl-4 cell-auto text-center">
                                                                                                {{$t('message.ProductIndex.table.stockCard.last_inventory')}}
                                                                                            </th>
                                                                                            <th scope="col" class="pl-4 cell-auto">
                                                                                                {{$t('message.ProductIndex.table.stockCard.transporter')}}
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <template v-if="this.defaultStockCard.filter?.length > 0">
                                                                                            <template v-for="(stock, i) in this.defaultStockCard.filter" :key="i">
                                                                                                <template v-if="i >= (this.paginateDetailStockCard.currentPage - 1)*10 && i < this.paginateDetailStockCard.currentPage*10">
                                                                                                    <tr class="table-header border">
                                                                                                        <th scope="col" class="pl-4 cell-code text-primary" @click="showDetailStockCard(stock.id, stock.type_eng)" v-text="stock.code"></th>
                                                                                                        <th scope="col" class="pl-4 cell-auto text-center font-weight-normal" >{{stock.date}}</th>
                                                                                                        <th scope="col" class="pl-4 cell-release-coupon text-center font-weight-normal" v-text="stock.type"></th>
                                                                                                        <th scope="col" class="pl-4 cell-auto text-center font-weight-normal" v-text="n(stock.price)"></th>
                                                                                                        <th scope="col" class="pl-4 cell-auto text-center font-weight-normal" v-text="numberFloat(stock.quantity)"></th>
                                                                                                        <th scope="col" class="pl-4 cell-auto text-center font-weight-normal" v-text="n(stock.lastInventory)"></th>
                                                                                                        <th scope="col" class="pl-4 cell-auto font-weight-normal" v-text="stock.partner"></th>
                                                                                                    </tr>
                                                                                                </template>
                                                                                            </template>
                                                                                        </template>
                                                                                    </tbody>
                                                                                </table>
                                                                                <Pagination :value="paginateDetailStockCard" :record="10" v-if="this.paginateDetailStockCard.totalRecord >= 10"/>
                                                                                <div class="no-data border border-top-0" v-if="!this.defaultStockCard.filter || this.defaultStockCard.filter?.length == 0">
                                                                                    <span>{{ $t('message.common.notFound') }}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr class="detail-row bg-white border__body-detail" v-if="productAttrActive === attr.id && headerActive === 'maxInventory'">
                                                                    <td :colspan="fields.length + 1" class="border-top-0">
                                                                        <div class="row detail-prd-row_detail mx-0 mt-3 pr-4 mb-4">
                                                                            <div class="col-12 p-0">
                                                                                <table class="w-100">
                                                                                    <thead>
                                                                                        <tr class="backgroup__detail table-header border-1">
                                                                                            <th scope="col" class="pl-4 cell-code">
                                                                                                {{$t('message.ProductIndex.branch')}}
                                                                                            </th>
                                                                                            <th scope="col" class="pl-4 cell-auto text-center">
                                                                                                {{$t('message.ProductIndex.table.maxInventory.on_show_quantity')}}
                                                                                            </th>
                                                                                            <th scope="col" class="pl-4 cell-release-coupon text-center">
                                                                                                {{$t('message.ProductIndex.table.maxInventory.min_inventory')}}
                                                                                            </th>
                                                                                            <th scope="col" class="pl-4 cell-auto text-center">
                                                                                                {{$t('message.ProductIndex.table.maxInventory.max_inventory')}}
                                                                                            </th>
                                                                                            <th scope="col" class="pl-4 cell-auto"></th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr class="table-header-search border">
                                                                                            <th scope="col" class="pl-4 cell-code position-relative">
                                                                                                <input type="text" v-model="this.defaultMaxInventory.search" :placeholder="$t('message.ProductIndex.placeholder.branch')" class="form-control input-custom bg-transparent pr-4" autocomplete="off"  @input="onInputFilterMaxInventory">
                                                                                                <i class="fa fa-search mr-2 text-gray icon-search_input"></i>
                                                                                            </th>
                                                                                            <th scope="col" class="pl-4 cell-auto text-center"></th>
                                                                                            <th scope="col" class="pl-4 cell-release-coupon text-center"></th>
                                                                                            <th scope="col" class="pl-4 cell-auto text-center"></th>
                                                                                            <th scope="col" class="pl-4 cell-auto"></th>
                                                                                        </tr>
                                                                                        <template v-if="this.defaultMaxInventory.filter?.length > 0">
                                                                                            <template v-for="(inventory, i) in this.defaultMaxInventory.filter" :key="i">
                                                                                                <template v-if="i >= (this.paginateDetailMaxInventory.currentPage - 1)*10 && i < this.paginateDetailMaxInventory.currentPage*10">
                                                                                                    <tr class="table-header border">
                                                                                                        <th scope="col" class="pl-4 cell-code position-relative font-weight-normal" v-text="inventory.branch ? inventory.branch.name : null"></th>
                                                                                                        <th scope="col" class="pl-4 cell-auto text-center font-weight-normal">
                                                                                                            <template v-if="this.inventoryEditId == inventory.id">
                                                                                                                <InputNumberFormat v-model="inventory.onShowRoomQuantityDraft" :min="0" class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true" :inputMarginAuto="true"></InputNumberFormat>
                                                                                                            </template>
                                                                                                            <template v-else>
                                                                                                                <span v-text="n(inventory.onShowRoomQuantity)"></span>
                                                                                                            </template>
                                                                                                        </th>
                                                                                                        <th scope="col" class="pl-4 cell-release-coupon text-center font-weight-normal">
                                                                                                            <template v-if="this.inventoryEditId == inventory.id">
                                                                                                                <InputNumberFormat v-model="inventory.minimalQuantityDraft" :min="0" class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true" :inputMarginAuto="true"></InputNumberFormat>
                                                                                                            </template>
                                                                                                            <template v-else>
                                                                                                                <span v-text="n(inventory.minimalQuantity)"></span>
                                                                                                            </template>
                                                                                                        </th>
                                                                                                        <th scope="col" class="pl-4 cell-auto text-center font-weight-normal">
                                                                                                            <template v-if="this.inventoryEditId == inventory.id">
                                                                                                                <InputNumberFormat v-model="inventory.maximumQuantityDraft" :min="0" class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true" :inputMarginAuto="true"></InputNumberFormat>
                                                                                                            </template>
                                                                                                            <template v-else>
                                                                                                                <span v-text="n(inventory.maximumQuantity)"></span>
                                                                                                            </template>
                                                                                                        </th>
                                                                                                        <th scope="col" class="pl-4 cell-auto">
                                                                                                            <div class="flex-end">
                                                                                                                <template v-if="this.inventoryEditId == inventory.id">
                                                                                                                    <AppPermission :permission="[this.$permissions.products.create ?? '', this.$permissions.products.update ?? '']">
                                                                                                                        <button class="btn btn-success py-1 mr-2" @click="saveMaxInventory(inventory, entry)">{{$t('message.button.save')}}</button>                                                                                                                                                                  
                                                                                                                    </AppPermission>
                                                                                                                    <button class="btn btn-default py-1 mr-2" @click="changeInventoryEditId(null, inventory)">{{$t('message.button.cancel')}}</button>
                                                                                                                </template>
                                                                                                                <template v-else>
                                                                                                                    <AppPermission :permission="[this.$permissions.products.create ?? '', this.$permissions.products.update ?? '']">
                                                                                                                    <button class="btn btn-save py-1 mr-2" @click="changeInventoryEditId(inventory.id, inventory)">{{$t('message.ProductIndex.button.update')}}</button>
                                                                                                                    </AppPermission>
                                                                                                                </template>
                                                                                                            </div>
                                                                                                        </th>
                                                                                                    </tr>
                                                                                                </template>
                                                                                            </template>
                                                                                        </template>
                                                                                    </tbody>
                                                                                </table>
                                                                                <Pagination :value="paginateDetailMaxInventory" :record="10" v-if="this.paginateDetailMaxInventory.totalRecord >= 10"/>
                                                                                <div class="no-data border border-top-0" v-if="!this.defaultMaxInventory.filter || this.defaultMaxInventory.filter?.length == 0">
                                                                                    <span>{{ $t('message.common.notFound') }}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr class="detail-row bg-white border__body-detail" v-if="productAttrActive === attr.id && headerActive === 'inventory'">
                                                                    <td :colspan="fields.length + 1" class="border-top-0">
                                                                        <div class="row detail-prd-row_detail mx-0 mt-3 pr-4 mb-4">
                                                                            <div class="col-12 p-0">
                                                                                <table class="w-100">
                                                                                    <thead>
                                                                                        <tr class="backgroup__detail table-header border-1">
                                                                                            <th scope="col" class="pl-4 cell-code">
                                                                                                {{$t('message.ProductIndex.branch')}}
                                                                                            </th>
                                                                                            <th scope="col" class="pl-4 cell-auto">
                                                                                                {{$t('message.ProductIndex.inventory')}}
                                                                                            </th>
                                                                                            <th scope="col" class="pl-4 cell-auto">
                                                                                                {{$t('message.ProductIndex.table.inventory.quantity_order')}}
                                                                                            </th>
                                                                                            <th scope="col" class="pl-4 cell-release-coupon">
                                                                                                {{$t('message.ProductIndex.table.inventory.customer_order')}}
                                                                                            </th>
                                                                                            <th scope="col" class="pl-4 cell-auto">
                                                                                                {{$t('message.ProductIndex.table.inventory.expected_out_of_stock')}}
                                                                                            </th>
                                                                                            <th scope="col" class="pl-4 cell-status">
                                                                                                {{$t('message.ProductIndex.status')}}
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr class="table-header-search border">
                                                                                            <th scope="col" class="pl-4 cell-code-coupon position-relative">
                                                                                                <input type="text" v-model="this.defaultProductBranchMeta.search" :placeholder="$t('message.ProductIndex.placeholder.branch')" class="form-control input-custom bg-transparent pr-4" autocomplete="off" @input="onInputFilterBranch">
                                                                                                <i class="fa fa-search mr-2 text-gray icon-search_input"></i>
                                                                                            </th>
                                                                                            <th scope="col" class="pl-4 cell-auto"></th>
                                                                                            <th scope="col" class="pl-4 cell-auto"></th>
                                                                                            <th scope="col" class="pl-4 cell-release-coupon"></th>
                                                                                            <th scope="col" class="pl-4 cell-auto"></th>
                                                                                            <th scope="col" class="pl-4 cell-auto"></th>
                                                                                        </tr>
                                                                                        <template v-if="this.defaultProductBranchMeta.filter?.length > 0">
                                                                                            <template v-for="(inventory, i) in this.defaultProductBranchMeta.filter" :key="i">
                                                                                                <template v-if="i >= (this.paginateDetailInventory.currentPage - 1)*10 && i < this.paginateDetailInventory.currentPage*10">
                                                                                                    <tr class="table-header border">
                                                                                                        <th scope="col" class="pl-4 cell-code font-weight-normal" v-text="inventory.branch?.name"></th>
                                                                                                        <th scope="col" class="pl-4 cell-auto font-weight-normal" v-text="numberFloat(inventory.inventories ? inventory.inventories : 0)"></th>
                                                                                                        <th scope="col" class="pl-4 cell-auto font-weight-normal" v-text="numberFloat(inventory.quantitySupplier ? inventory.quantitySupplier : 0)"></th>
                                                                                                        <th scope="col" class="pl-4 cell-release-coupon font-weight-normal" v-text="numberFloat(inventory.quantityOrder ? inventory.quantityOrder : 0)"></th>
                                                                                                        <th scope="col" class="pl-4 cell-auto font-weight-normal"></th>
                                                                                                        <th scope="col" class="pl-4 cell-status font-weight-normal" v-text="status(inventory.status)"></th>
                                                                                                    </tr>
                                                                                                </template>
                                                                                            </template>
                                                                                        </template>
                                                                                    </tbody>
                                                                                </table>
                                                                                <Pagination :value="paginateDetailInventory" :record="10" v-if="this.paginateDetailInventory.totalRecord >= 10"/>
                                                                                <div class="no-data border border-top-0" v-if="!this.defaultProductBranchMeta.filter || this.defaultProductBranchMeta.filter?.length == 0">
                                                                                    <span>{{ $t('message.common.notFound') }}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr class="detail-row bg-white border__body-detail" v-if="productAttrActive === attr.id && headerActive === 'batch'">
                                                                    <td :colspan="fields.length + 1" class="border-top-0">
                                                                        <div class="row detail-prd-row_detail mx-0 mt-3 pr-4">
                                                                            <div class="col-12 p-0">
                                                                                <table class="w-100">
                                                                                    <thead>
                                                                                        <tr class="backgroup__detail table-header border-1">
                                                                                            <th scope="col" class="pl-4 cell-code">
                                                                                                {{$t('message.ProductIndex.table.batch.batch_number')}}
                                                                                            </th>
                                                                                            <th scope="col" class="pl-4 cell-auto text-center">
                                                                                                {{$t('message.ProductIndex.table.batch.expired')}}
                                                                                            </th>
                                                                                            <th scope="col" class="pl-4 cell-auto text-center">
                                                                                                {{$t('message.ProductIndex.quantity')}}
                                                                                            </th>
                                                                                            <th scope="col" class="pl-4 cell-status">
                                                                                                {{$t('message.ProductIndex.status')}}
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr class="table-header-search border">
                                                                                            <th scope="col" class="pl-4 cell-code position-relative">
                                                                                                <input type="text" v-model="this.defaultProductBatch.search" :placeholder="$t('message.ProductIndex.placeholder.batch')" class="form-control input-custom bg-transparent pr-0" autocomplete="off" @input="onInputFilterBatch">
                                                                                                <i class="fa fa-search mr-2 text-gray icon-search_input"></i>
                                                                                            </th>
                                                                                            <th scope="col" class="pl-4 cell-auto text-center"></th>
                                                                                            <th scope="col" class="pl-4 cell-auto text-center" v-text="n(entry.totalQuantityConsignments)"></th>
                                                                                            <th scope="col" class="pl-4 cell-status">
                                                                                                <SelectSearch :modelValue="this.defaultProductBatch.status" :readonly="true" :defaultOption="this.$constant.optionStatusConsignment" @update:modelValue="doFilterSearchBatchList" :label="'statusConsignment'"/>
                                                                                            </th>
                                                                                        </tr>
                                                                                        <template v-if="this.defaultProductBatch.filter?.length > 0">
                                                                                            <template v-for="(consignment, i) in this.defaultProductBatch.filter" :key="i">
                                                                                                <template v-if="i >= (this.paginateDetailBatch.currentPage - 1)*10 && i < this.paginateDetailBatch.currentPage*10">
                                                                                                    <tr class="table-header border">
                                                                                                        <th scope="col" class="pl-4 cell-code font-weight-normal"><span class="consignment_name mr-2 text-white" v-text="consignment.name"></span></th>
                                                                                                        <th scope="col" class="pl-4 cell-auto font-weight-normal text-center" v-text="d(consignment.expried)"></th>
                                                                                                        <th scope="col" class="pl-4 cell-auto font-weight-normal text-center" v-text="numberFloat(consignment.quantity)"></th>
                                                                                                        <th scope="col" class="pl-4 cell-status font-weight-normal" v-text="consignment.status"></th>
                                                                                                    </tr>
                                                                                                </template>
                                                                                            </template>
                                                                                        </template>
                                                                                    </tbody>
                                                                                </table>
                                                                                <Pagination :value="paginateDetailBatch" :record="10" v-if="this.paginateDetailBatch.totalRecord >= 10"/>
                                                                                <div class="no-data border border-top-0" v-if="!this.defaultProductBatch.filter || this.defaultProductBatch.filter?.length == 0">
                                                                                    <span>{{ $t('message.common.notFound') }}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row detail-prd-row_detail mx-0 my-3 pr-4">
                                                                            <div class="col-12 flex-end pr-0">
                                                                                    <AppPermission :permission="this.$permissions.products.export">
                                                                                        <button type="button" class="btn btn-secondary text-white mx-1" @click="exportDetailConsignment(this.defaultProductBatch.filter)">
                                                                                            <i class="fa fa-file-export" aria-hidden="true"></i>
                                                                                            {{$t('message.ProductIndex.button.export_file')}}
                                                                                        </button>
                                                                                    </AppPermission>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr class="detail-row bg-white border__body-detail" v-if="productAttrActive === attr.id && headerActive === 'combo'">
                                                                    <td :colspan="fields.length + 1" class="border-top-0">
                                                                        <div class="row detail-prd-row_detail mx-0 mt-3 pr-4">
                                                                            <div class="col-12 p-0">
                                                                                <table class="w-100">
                                                                                    <thead>
                                                                                        <tr class="backgroup__detail table-header border-1">
                                                                                            <th scope="col" class="pl-4 cell-code">
                                                                                                {{$t('message.ProductIndex.table.combo.STT')}}
                                                                                            </th>
                                                                                            <th scope="col" class="pl-4 cell-auto text-center">
                                                                                                {{$t('message.ProductIndex.code')}}
                                                                                            </th>
                                                                                            <th scope="col" class="pl-4 cell-auto text-center">
                                                                                                {{$t('message.ProductIndex.table.combo.component_row_name')}}
                                                                                            </th>
                                                                                            <th scope="col" class="pl-4 cell-status">
                                                                                                {{$t('message.ProductIndex.quantity')}}
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <template v-if="entry.combo_products?.length > 0">
                                                                                            <tr v-for="(combo, i) in entry.combo_products" :key="i" class="table-header border">
                                                                                                <th scope="col" class="pl-4 cell-code font-weight-normal" v-text="n(i + 1)"></th>
                                                                                                <th scope="col" class="pl-4 cell-auto font-weight-normal text-center" v-text="combo.code"></th>
                                                                                                <th scope="col" class="pl-4 cell-auto font-weight-normal text-center" v-text="combo.name"></th>
                                                                                                <th scope="col" class="pl-4 cell-status font-weight-normal" v-text="combo.quantity"></th>
                                                                                            </tr>
                                                                                        </template>
                                                                                    </tbody>
                                                                                </table>
                                                                                <div class="no-data border border-top-0" v-if="entry.combo_products?.length == 0">
                                                                                    <span>{{ $t('message.common.notFound') }}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row detail-prd-row_detail mx-0 my-3 pr-4">
                                                                            <div class="col-12 flex-end pr-0">
                                                                                <AppPermission :permission="'Products.export'">
                                                                                    <button type="button" class="btn btn-secondary text-white mx-1" @click="exportCombo(entry.id)">
                                                                                        <i class="fa fa-file-export" aria-hidden="true"></i>
                                                                                        {{$t('message.ProductIndex.button.export_file')}}
                                                                                    </button>
                                                                                </AppPermission>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr class="detail-row bg-white border__footer-detail" v-if="productAttrActive === attr.id"></tr>
                                                            </template>
                                                        </tbody>
                                                    </table>
                                                </th>
                                            </tr>
                                            <tr class="detail-row bg-white border__body-detail" :id="'prd-' + entry.id" v-if="entry.id === this.productDetail">
                                                <th style="min-width:40px;" class="border-top-0">
                                                </th>
                                                <td :colspan="this.fields.length" class="border-top-0">
                                                    <div class="mt-2 pb-4">
                                                        <div class="row detail-prd-row flex-end mt-4 pr-5">
                                                            <AppPermission :permission="this.$permissions.products.update">
                                                                <button type="button" class="btn btn-save mx-1" @click="showModalProductsEditById(entry.id)">
                                                                    <i class="fa fa-check"></i>
                                                                    {{$t('message.ProductIndex.button.update')}}
                                                                </button>
                                                            </AppPermission>
                                                                <button type="button" class="btn btn-secondary text-white mx-1" @click="showModalProductsBarcode(entry)">
                                                                    <i class="fa fa-barcode"></i>
                                                                    {{$t('message.ProductIndex.button.print')}}
                                                                </button> 
                                                                <button type="button" class="btn btn-save mx-1" @click="copy(entry.id)">
                                                                    <i class="fa fa-copy"></i>
                                                                    {{$t('message.ProductIndex.button.copy')}}
                                                                </button>
                                                            <AppPermission :permission="this.$permissions.products.inActive">                                                            
                                                                <button type="button" class="btn btn-danger mx-1" @click="showModalProductStopWorking(entry.id, entry.code, 1)" v-if="setStatusByBranch(entry, entry.id) == this.$constant.productActive">
                                                                    <i class="fa fa-lock"></i>
                                                                    {{$t('message.ProductIndex.button.in_active')}}
                                                                </button>                                                                                                    
                                                                <button v-else type="button" class="btn btn-primary mx-1" @click="showModalProductStopWorking(entry.id, entry.code, 0)">
                                                                    <i class="fa fa-unlock-alt" aria-hidden="true"></i>
                                                                    {{$t('message.ProductIndex.button.active')}}
                                                                </button>
                                                            </AppPermission>
                                                            <AppPermission :permission="this.$permissions.products.remove">
                                                                <button type="button" class="btn btn-danger mx-1 mr-4" @click="showModalProductRemove(entry)">
                                                                    <i class="fa fa-trash-alt"></i>
                                                                    {{$t('message.ProductIndex.button.remove')}}
                                                                </button>
                                                            </AppPermission>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="detail-row bg-white border__footer-detail" v-if="productDetail === entry.id"></tr>
                                        </template>
                                    </template>
                                </tbody>
                            </table>
            <!--                        <Paginate :value="paginate" :record="this.numberRecord" @change="pageChange" />-->
                        </div>
                        <div class="row" v-if="entries?.length == 0" id="notFound">
                            <div class="col-sm-12 text-center my-10 text-secondary align-items-center d-flex justify-content-center">
                                <i class="fa fa-hdd-o mr-2 text-secondary"></i>
                                <p v-if="(filter.keyword || filter.created_at || this.filterCategories || this.filterType) && this.loading === false">
                                    {{$t('message.common.notData')}}
                                </p>
                                <p v-else-if="this.loading === false">
                                    {{$t('message.common.notData')}}
                                </p>
                            </div>
                        </div>
                        <div class="row" v-if="getDataChecked(this.fields) == 0 && entries?.length > 0" id="notFound">
                            <div class="col-sm-12 text-center my-10">
                                <p> 
                                    {{$t('message.ProductIndex.no_field')}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalFileUploadProducts ref="ModalFileUploadProducts" @inputData="errorMsgShow(this.errMsgShow)" @reloadData="load()"/>
        <ModalProductsChecked ref="ModalProductsChecked" @removeData="removeChecked()" @uncheckedItem="countChecked"/>
        <ModalProductsEdit ref="ModalProductsEdit" @resetCount="resetCount()" @inputData="load()" @inputEntry="1" :typeEditAdd="typeEditAdd" :idProduct="idProduct"/>
        <ModalProductRemove ref="ModalProductRemove" @removeData="removeChecked()" @inputData="load()"/>
        <ModalProductStopWorking ref="ModalProductStopWorking"  @inputData="load()" />                    
        <ModalProductsAdd ref="ModalProductsAdd" @resetCount="resetCount()" @inputData="load()" @inputEntry="1" :typeEditAdd="typeEditAdd"/>
        <ModalCategoryAdd ref="ModalCategoryAdd" :typeEditAdd="typeEditAdd" @inputData="getDataCategories()" :isFirstModal="true"/>
        <!-- Barcode -->                    
        <ModalTypePapers ref="ModalTypePapers" :parent="true"/>
        <ModalMultipleProductsBarcode ref="ModalMultipleProductsBarcode"/>
        <ModalConfirmCopyProduct ref="ModalConfirmCopyProduct" @updated="load()" />
        <ModalErrorMessages ref="ModalErrorMessages" :update="true"/>
        <DetailStockCard ref="DetailStockCard" />
        <ModalSelectOptionExport ref="ModalSelectOptionExport" @export="exportFiles"/>
    </AppPermission>
</template>

<script>
import ModalErrorMessages from '@/components/ModalErrorMessages.vue';
import ModalSelectOptionExport from '@/components/ModalSelectOptionExport.vue';
import { $alert, $get, $post, clone, setArray, getSearchQuery, explode, numberFormat, parseIntEx, removeVietnameseTones, toastCutomBottom, forEach } from "@/lib/utils";
import Pagination from "@/components/Pagination";
import TableSortField from "@/components/TableSortField";
// import QSelect from "../../components/QSelect";
import InputDateRangePicker from "../../components/forms/InputDateRangePicker";
import SelectField from "../../components/SelectField";
import ModalFileUploadProducts from "./ModalFileUploadProducts.vue";
import store from "@/store";
import ModalProductsChecked from "./ModalProductsChecked.vue";
import ModalProductsEdit from "./tabs/ModalProductsEdit.vue";
import ModalProductRemove from "./ModalProductRemove.vue";
import ModalMultipleProductsBarcode from "./tabs/ModalMultipleProductsBarcode.vue";
import ModalProductsAdd from "./tabs/ModalProductsAdd.vue";
import ModalCategoryAdd from "../categories/tabs/ModalCategoryAdd.vue";
import emitter from "@/lib/emitter";
import InputQuickSearch from "@/components/forms/InputQuickSearch.vue";
import ModalProductStopWorking from './ModalProductStopWorking';
import http from '@/services/api';
import ModalTypePapers from './tabs/ModalTypePapers';
import DetailStockCard from './DetailStockCard';
import moment from 'moment';
import SelectSearch from "@/components/SelectSearch.vue";
import InputNumberFormat from "@/components/forms/InputNumberFormat";
import AppPermission from '@/components/AppPermission';
import ModalConfirmCopyProduct from './tabs/ModalConfirmCopy.vue';
import exportMixin from '@/exportMixin';
import storeModul from "@/storeModul";
export default {
    name: "ProductIndex",
    mixins: [exportMixin],
    components: { 
        ModalErrorMessages, InputQuickSearch, SelectField, InputDateRangePicker, ModalCategoryAdd, ModalProductStopWorking, ModalConfirmCopyProduct,
        TableSortField, Pagination, ModalFileUploadProducts, ModalProductsChecked, ModalProductsEdit, ModalProductRemove, ModalMultipleProductsBarcode, ModalProductsAdd,
        ModalTypePapers, DetailStockCard, SelectSearch, InputNumberFormat,
        AppPermission, ModalSelectOptionExport
    },
    mounted() {

        document.title = this.$t('message.ProductIndex.document_title');
        // const fields = localStorage.getItem('productsSelectedFields_' + this.user.id);
        // if (fields) {
        //     this.fields = JSON.parse(fields);
        // }
        this.numberRecord = this.$route.query.numberRecord || "15";
        const query = clone(this.$route.query);
        this.getDataCategories();
        if(query['code'] != null){
            this.filterQuery['product_code_name'] = query['code'];
            this.loadDetail();
        }else{
            this.clearFilter();
        }
        window.addEventListener('scroll', this.onScroll);
    },
    created() {
        emitter.on("clearIdProduct", () => {
            this.idProduct = null;
        });
        emitter.on("loadDataProducts", () => {
            this.load()
        });
    },
    data() {
        const query = getSearchQuery();
        return {
            errMsgShow:true,
            productId:'',
            productUnitId:[],
            productUnits:[],
            count: 0,
            id: [],
            prdID: [],
            details: [],
            filter: {
                keyword: query.keyword,
                created_at: query.created_at || '',
                categories: explode(query.categories),
                inventory: explode(query.inventory),
                suppliers: explode(query.suppliers),
                type: explode(query.type),
                product_code_name: '',
                note_description: '',
            },
            inventoryOptions: [
                { id: 1, label: 'Dưới tồn kho min' },
                { id: 2, label: 'Vượt tồn kho max' },
                { id: 3, label: 'Còn hàng trong kho' },
                { id: 4, label: 'Hàng cận date' },
                { id: 5, label: 'Hàng bán chậm' },
            ],
            options:[
                { id: 1, label: this.$t('message.ProductIndex.button.order_supplier'), icon:'fas fa-file-import pr-2', link:'/order-suppliers/form',target:'_blank'},
                { id: 2, label: this.$t('message.ProductIndex.button.purchase_product'), icon:'fas fa-file-signature pr-2', link:'/purchase-orders/form',target:'_blank'},
                { id: 3, label: this.$t('message.ProductIndex.button.print'), icon:'fa fa-barcode pr-2', link:'',target:'_blank', click:'showModalMultipleProductsBarcode()'},
                { id: 4, label: this.$t('message.ProductIndex.button.fix'), icon:'fa fa-edit pr-2', click:'showModalProductsEdit()', name:'edit'},
                { id: 5, label: this.$t('message.ProductIndex.button.remove'), icon:'far fa-trash-alt pr-2', click:"showModalProductRemove()"},

                { id: 6, label: this.$t('message.ProductIndex.button.add_order'), icon:'fas fa-cart-plus pr-2', link:'/sale/create-order?type=short-create-order',target:'_blank', 'name': 'CreateOrder', 'type': 'short-create-order'},
                { id: 7, label: this.$t('message.ProductIndex.button.add_invoice'), icon:'fas fa-file-invoice-dollar pr-2', link:'/sale/create-order?type=short-create-invoice',target:'_blank', 'name': 'CreateOrder', 'type': 'short-create-invoice' },
                { id: 8, label: this.$t('message.ProductIndex.button.order_supplier'), icon:'fas fa-file-invoice-dollar pr-2', link:'/order-supplier/create?type=short-create-order-supplier',target:'_blank', 'name': 'orderSupplierCreate', 'type': 'short-create-order-supplier' },
                { id: 9, label: this.$t('message.ProductIndex.button.purchase_product'), icon:'fas fa-file-invoice-dollar pr-2', link:'/order-supplier/create-import?type=short-create-import',target:'_blank', 'name': 'ImportSupplierCreate', 'type': 'short-create-import' },
                { id: 9, label: this.$t('message.ProductIndex.button.inventory_plan'), icon:'fas fa-file-invoice-dollar pr-2', link:'/inventory-plans?type=short-inventories-plan',target:'_blank', 'name': 'PlansCreateUpdateScreen', 'type': 'short-inventories-plan' },

            ],
            checkedAll: false,
            fields: [],
            entries: [],
            sortData: {
                field: 'id',
                direction: 'desc',
            },
            paginate: {
                totalRecord: null,
                currentPage: null,
                lastPage: null,
            },
            user: store.state.Auth.user,
            excelData: {
                fields: null,
                data: [],
                fileName: ''
            },
            orderDetailsSumByProductUnitMap: [],
            actualInventoryMap: [],
            show:true,
            suppliers: [],
            toasts: [],
            typeEditAdd: "ADD",
            idProduct: null,
            idCategory: null,
            categories:[],
            fixed: false,
            numberRecord: 15,
            product_detail: {},
            productTypes: this.$constant.productTypes,
            filterType: [],
            filterCategories: [],
            filterQuery: {},
            mainImage: null,
            parentCategories: [],
            choosedParentCategories: [],
            childCategories: {},
            inputFilterCategories: '',
            displayCategories: [],
            isEdit: null,
            loading: false,
            arrayChecked: [],
            sttActive: null,
            statusBusiness: [
                { "id": 1, "name": "message.ProductIndex.active", "language": true },
                { "id": 0, "name": "message.ProductIndex.inActive", "language": true },
                { "id": null, "name": "message.ProductIndex.all", "language": true }
            ],
            branchID: store.state.CurrentBranch?.id,
            statusByBranch: 1,
            productDetail: null,
            state: store.state,
            headerActive: 'info',
            paginateDetailInventory: {
                currentPage: 1,
                lastPage: 1,
                totalRecord: 1,
            },
            defaultProductBranchMeta:{
                filter: null,
                all: null,
                search: '',
            },
            paginateDetailBatch: {
                currentPage: 1,
                lastPage: 1,
                totalRecord: 1,
            },
            defaultProductBatch:{
                filter: null,
                all: null,
                search: '',
                status: null,
            },
            paginateDetailStockCard: {
                currentPage: 1,
                lastPage: 1,
                totalRecord: 1,
            },
            defaultStockCard:{
                filter: null,
                all: null,
                search: '',
            },
            paginateDetailMaxInventory: {
                currentPage: 1,
                lastPage: 1,
                totalRecord: 1,
            },
            defaultMaxInventory:{
                filter: null,
                all: null,
                search: '',
            },
            inventoryEditId: null,
            productAttrActive: null,
        }
    },
    methods: {  
        async changeQuickSearch(object){
            const query = clone(this.$route.query);
            Object.keys(this.filter).forEach(key => {
                object.forEach(obj => {
                    Object.keys(obj).forEach(o => {
                        if(key == o && obj[o] != ''){
                            this.filterQuery[key] = obj[o];
                        }else if(key == o && obj[o] == ''){
                            delete this.filterQuery[key];
                        }
                    });
                })
            });
            query['page'] = this.filterQuery.page = 1;
            await this.$router.push({query: query});
            this.load(this.filterQuery);
        },   
        imageUrlAlt(event) {
            event.target.src = "/img/default-product.png"
        },
        errorMsgShow(value) {
            this.errMsgShow = value;
        },
        showModalCategoryEdit(id){
            this.typeEditAdd = "EDIT";
            this.idCategory = id;
            this.$refs.ModalCategoryAdd.show(id);
        },
        checkHaveChildCategories(data){
            if(data?.children) return true
            return false
        },
        onInputFilterCategories(){
            const categories = this.categories
            if(this.inputFilterCategories ==''){
                this.displayCategories = categories
                this.choosedParentCategories = []
                return
            }
            let arr = []
            let idList = []
            categories.forEach(item => {
                if(item.children && item.children?.length){
                    let newItem
                    let dataSearch = [];
                    item.children.forEach(child => {
                        let newChild
                        if(child.children && child.children?.length){
                            let newChildChildren = child.children.filter(e => removeVietnameseTones(e.name.toLowerCase()).includes(removeVietnameseTones(this.inputFilterCategories.toLowerCase())))
                            
                            if(newChildChildren?.length && !(arr.includes(item))){
                                idList.push(child?.id);
                                newChild = [{...child,children: newChildChildren}];
                                newItem = {...item,children: newChild};
                            }else if(removeVietnameseTones(item.name.toLowerCase()).includes(removeVietnameseTones(this.inputFilterCategories.toLowerCase())) && !(arr.includes(item))){
                                arr.push(item)
                            }
                            if(removeVietnameseTones(child.name.toLowerCase()).includes(removeVietnameseTones(this.inputFilterCategories.toLowerCase())) && !(arr.includes(item))){
                                dataSearch.push(child)
                                newItem = {...item, children: dataSearch}
                                idList.push(item.id)
                                arr.push(newItem)
                            }
                        }else{
                            if(removeVietnameseTones(item.name.toLowerCase()).includes(removeVietnameseTones(this.inputFilterCategories.toLowerCase())) && !(arr.includes(item))){
                                arr.push(item)
                            }
                            if(removeVietnameseTones(child.name.toLowerCase()).includes(removeVietnameseTones(this.inputFilterCategories.toLowerCase())) && !(arr.includes(item))){
                                dataSearch.push(child)
                                newItem = {...item, children: dataSearch}
                                idList.push(item.id)
                                arr.push(newItem)
                            }
                        }
                    })
                    if(newItem && !(arr.includes(newItem))){
                        idList.push(newItem.id)
                        arr.push(newItem)
                    }
                    
                }else if(removeVietnameseTones(item.name.toLowerCase()).indexOf(removeVietnameseTones(this.inputFilterCategories.toLowerCase())) > -1 && !(arr.includes(item))){
                    arr.push(item)
                }
            })
            
            this.choosedParentCategories = [...idList]
            this.displayCategories = [...arr]
        },
        onChooseParentCategory(id){
            let arr = this.choosedParentCategories
            if(arr.includes(id)){
                arr = arr.filter(item => item !== id)
            }else{
                arr.push(id)
            }
            this.choosedParentCategories = [...arr]
        },
        onChooseImage(url){
            this.mainImage = url
        },
        formatNumber(a){
            if (a === null) {
                return null;
            }

            a = parseIntEx(a);
            return numberFormat(a)
        },
        onMouseLeave(){
            emitter.emit("mouseleave");
        },

        showModalProductRemove(data = null) {
            this.$refs.ModalProductRemove.show(data, this.arrayChecked);
        },
        async showModalProductStopWorking(id, code, status){              
            this.$refs.ModalProductStopWorking.show(id, code, status);
        },
        async onChange(v) {
            this.numberRecord = v.target.value
            let query = clone(this.$route.query);
            query['page']=1;
            query.numberRecord = v.target.value;
            await this.$router.push({query: query});
            this.load();
        },
        detailProductAttr(id){
            if (id === this.productAttrActive) {
                this.productAttrActive = null;
            } else {
                this.productAttrActive = id;
            }
            this.headerActive = 'info';
        },
        async detailProduct(id, entry = null){
            if (id === this.productDetail) {
                this.productDetail = null;
            } else {
                this.productDetail = id;
                // if(id){
                //     await this.getStockCard(id);
                //     if(entry){
                //         await this.getMaxInventory(id, entry);
                //         await this.getConsignmentForProduct(id, entry);
                //     }
                // }
            }
            this.productAttrActive = null;
            this.inventoryEditId = null;
            // if(entry && entry.consignments){
            //     this.defaultProductBatch.filter = this.defaultProductBatch.all = entry.consignments;
            //     this.defaultProductBatch.status = 0;
            //     this.setPaginateBatch();
            // }
            this.mainImage = null;
            this.headerActive = 'info';
        },
        async getStockCard(entry, attribute = null){
            if(entry){
                const res = await $post('/v1/products/get-stock-card', {id: entry.id, productUnitId: entry.currentProductUnitId, branchId: this.branchID, attribute: (attribute ? attribute.id : null)});
                if(res.status && res.data){
                    res.data.sort(function(a, b) { return new Date(b.date) - new Date(a.date) });
                    this.defaultStockCard.filter = this.defaultStockCard.all = res.data;
                    this.setPaginateStockCard();
                }             
            }
        },

        async getConsignmentForProduct(id, entry, attribute = null){
            if(id){
                const res = await $post('/v1/products/get-consignment-for-product', {productId: id, branchId: this.branchID, productUnitId: entry.currentProductUnitId, attribute: (attribute ? attribute.id : null)});
                if(res.status && res.data){
                    entry.totalQuantityConsignments = res.totalQuantity;
                    this.defaultProductBatch.filter = this.defaultProductBatch.all = res.data;
                    this.defaultProductBatch.status = 0;
                    this.setPaginateBatch();
                }                   
            }
        },

        async getMaxInventory(id, entry, attribute = null){
            if(id){
                let productUnitId = null;
                if (!attribute) {
                    productUnitId = entry.currentProductUnitId;
                }
                const res = await $post('/v1/products/get-max-inventory', {id: id, productUnitId: productUnitId, attribute: (attribute ? attribute.id : null)});
                if(res.status && res.data){
                    this.defaultProductBranchMeta.filter = this.defaultProductBranchMeta.all = res.data;
                    this.defaultMaxInventory.filter = this.defaultMaxInventory.all = res.data;
                    this.setPaginate();
                    this.setPaginateMaxInventory();
                }                   
            }
        },

        setPaginate(){
            this.paginateDetailInventory.currentPage = 1;
            this.paginateDetailInventory.lastPage = Math.ceil(this.defaultProductBranchMeta.filter.length / 10);
            this.paginateDetailInventory.totalRecord = this.defaultProductBranchMeta.filter.length;
        },
        setPaginateMaxInventory(){
            this.paginateDetailMaxInventory.currentPage = 1;
            this.paginateDetailMaxInventory.lastPage = Math.ceil(this.defaultMaxInventory.filter.length / 10);
            this.paginateDetailMaxInventory.totalRecord = this.defaultMaxInventory.filter.length;
        },
        setPaginateBatch(){
            this.paginateDetailBatch.currentPage = 1;
            this.paginateDetailBatch.lastPage = Math.ceil(this.defaultProductBatch.filter.length / 10);
            this.paginateDetailBatch.totalRecord = this.defaultProductBatch.filter.length;
        },
        setPaginateStockCard(){
            this.paginateDetailStockCard.currentPage = 1;
            this.paginateDetailStockCard.lastPage = Math.ceil(this.defaultStockCard.filter.length / 10);
            this.paginateDetailStockCard.totalRecord = this.defaultStockCard.filter.length;
        },
        onInputFilterBranch(){
            this.paginateDetailInventory.currentPage = 1;
            if(this.defaultProductBranchMeta.search.length == 0){
                this.defaultProductBranchMeta.filter = this.defaultProductBranchMeta.all;
                this.setPaginate();
                return;
            }
            let arr = [];
            for (let index = 0; index < this.defaultProductBranchMeta.all.length; index++) {
                if (this.defaultProductBranchMeta.all[index] != null && removeVietnameseTones(this.defaultProductBranchMeta.all[index].branch?.name.toLowerCase()).indexOf(removeVietnameseTones(this.defaultProductBranchMeta.search.toLowerCase())) > -1){
                    arr.push(this.defaultProductBranchMeta.all[index]);
                }
            }
            this.defaultProductBranchMeta.filter = arr;
            this.setPaginate();
        },
        onInputFilterMaxInventory(){
            this.paginateDetailMaxInventory.currentPage = 1;
            if(this.defaultMaxInventory.search.length == 0){
                this.defaultMaxInventory.filter = this.defaultMaxInventory.all;
                this.setPaginateMaxInventory();
                return;
            }
            let arr = [];
            for (let index = 0; index < this.defaultMaxInventory.all.length; index++) {
                if (this.defaultMaxInventory.all[index] != null && removeVietnameseTones(this.defaultMaxInventory.all[index].branch?.name.toLowerCase()).indexOf(removeVietnameseTones(this.defaultMaxInventory.search.toLowerCase())) > -1){
                    arr.push(this.defaultMaxInventory.all[index]);
                }
            }
            this.defaultMaxInventory.filter = arr;
            this.setPaginateMaxInventory();
        },
        onInputFilterBatch(){
            this.paginateDetailBatch.currentPage = 1;
            if(this.defaultProductBatch.search.length == 0){
                this.defaultProductBatch.filter = this.defaultProductBatch.all;
                this.filterStatus(this.defaultProductBatch.filter);
                this.setPaginateBatch();
                return;
            }
            let arr = [];
            for (let index = 0; index < this.defaultProductBatch.all.length; index++) {
                if (this.defaultProductBatch.all[index] != null && removeVietnameseTones(this.defaultProductBatch.all[index].name.toLowerCase()).indexOf(removeVietnameseTones(this.defaultProductBatch.search.toLowerCase())) > -1){
                    arr.push(this.defaultProductBatch.all[index]);
                }else if (this.defaultProductBatch.all[index] != null && removeVietnameseTones((moment(this.defaultProductBatch.all[index].expried).format('DD/MM/YYYY')).toLowerCase()).indexOf(removeVietnameseTones(this.defaultProductBatch.search.toLowerCase())) > -1){
                    arr.push(this.defaultProductBatch.all[index]);
                }
            }
            this.defaultProductBatch.filter = arr;
            this.filterStatus(arr);
            this.setPaginateBatch();
        },
        changeInventoryEditId(id, entry = null){
            this.inventoryEditId = id;
            if(entry){
                entry.maximumQuantityDraft = entry.maximumQuantity;
                entry.minimalQuantityDraft = entry.minimalQuantity;
                entry.onShowRoomQuantityDraft = entry.onShowRoomQuantity;
            }
        },
        async saveMaxInventory(data, entry){
            // if(entry.inventories < entry.onShowRoomQuantityDraft){
            //     $alert({code: 422, message: "Số lượng trưng bày không được lớn hơn tồn kho"});
            //     return;
            // }
            if(data.maximumQuantityDraft <= data.minimalQuantityDraft){
                $alert({code: 422, message: this.$t('message.ProductIndex.alerts.valid_max_inventory')});
                return;
            }
            const res = await $post('/v1/products/change-product-branch-meta', data);                   
            if(res && res.status){
                $alert({code: 200, message: res.message});
                if(this.productAttrActive){
                    this.getMaxInventory(entry.id, entry, {id: this.productAttrActive});
                }else{
                    await this.getMaxInventory(entry.id, entry, null);
                }
                this.changeInventoryEditId(null);
            }else{
                $alert({code: 404, message: res.message});
            }
        },
        filterStatus(arr = []){
            if(!this.defaultProductBatch.status){
                this.defaultProductBatch.filter = arr;
            }else{
                let array = [];
                arr.forEach(el => {
                    if(el.statusOrigin == this.defaultProductBatch.status){
                        array.push(el);
                    }
                });
                this.defaultProductBatch.filter = array;
            }
        },
        doFilterSearchBatchList(v){
            this.defaultProductBatch.status = v.model ?? null;
            this.onInputFilterBatch();
        },
        onScroll() {
            let height = window.scrollY;
            if(height > 120){
                 //Giữ vị trí cho thông báo lỗi    
                this.fixed = true;
            }else{
                this.fixed = false;
            }
            emitter.emit("position", 0);
           
            
        },
        //Ẩn hiện dropdown bên trái
        showHide(name,iDown, iUp){
            var dropdownContent = document.getElementById(name);
            dropdownContent.classList.toggle("dp-hide");
            var down = document.getElementById(iDown);
            down.classList.toggle("dp-hide");
            var up = document.getElementById(iUp);
            up.classList.toggle("dp-hide");
        },
        removeChecked(){
            this.arrayChecked = [];
            this.isCheckedAll();
        },
        isCheckedAll(){
            let getChecked = 0;
            this.entries.forEach((entry) => {
                const found = this.arrayChecked.find(id => id === entry.id);
                if(found){
                    getChecked += 1;
                    entry.checked = true;
                }
            });

            if(getChecked > 0 && getChecked == this.entries?.length){
                this.checkedAll = true;
            }else{
                this.checkedAll = false;
            }
        },
        countChecked(id){
            let arr = this.arrayChecked;
            if(arr.includes(id)){
                arr = arr.filter(item => item !== id)
            }else{
                arr.push(id)
            }
            this.arrayChecked = arr;
            this.isCheckedAll();
        },
        onCheckedAll(value) {
            setArray(this.entries, {
                checked: value
            });
            if(value == true){
                this.entries.forEach(entry => {
                    const found = this.arrayChecked.find(id => id === entry.id);
                    if(!found){
                        this.countChecked(entry.id);
                    }
                })
            }else{
                this.entries.forEach(entry => {
                    this.countChecked(entry.id);
                })
            }
        },
        isExistInArrayChecked(entry, array = []){
            if(array.includes(entry.id)){
                entry.checked = true;
                return true;
            }else{
                entry.checked = false;
                return false;
            }
        },
        getDataChecked(fields) {
            let count = 0;
            fields.forEach(item => {
                if (item.selected == true) count += 1;
            });
            return count;
        },
        showModalFileUploadProducts() {
            this.$refs.ModalFileUploadProducts.show();
        },
        showModalProductsChecked() {
            this.$refs.ModalProductsChecked.show(this.arrayChecked);
        },
        showModalProductsEdit(){
            emitter.emit('clearMain')
            emitter.emit('clearCombo')
            emitter.emit('clearThreshold')
            emitter.emit('clearIdProduct')
            emitter.emit('changeParam')
            this.typeEditAdd = "EDITS";
            this.idProduct = null;
            this.$refs.ModalProductsEdit.show(this.arrayChecked);
        },
        showModalProductsBarcode(data = null) {                    
            this.$refs.ModalTypePapers.show(this.$constant.typePrintSingle, data);            
        },
        async showModalMultipleProductsBarcode(obj, ids){
            const res = await $get('/v1/products/get-product-by-ids', {id: ids});            
            if(res && res.data != null) {
                this.$refs.ModalMultipleProductsBarcode.show(obj, res.data);
            }
        },
        changeCurrentUnit(entry, productUnitId) {
            const pu = entry.product_units.find(p => p.id === productUnitId);
            if (pu) {
                entry.basePrice = pu.price;
                if (entry.product_branch_meta) {
                    entry.product_branch_meta.forEach(pbm => {
                        if(pbm.productUnitId && pbm.productUnitId == productUnitId && pbm.branchId === this.branchID){
                            entry.inventories = pbm.inventories ?? 0;
                            entry.quantity_ordered = pbm.quantityOrder ?? 0;
                            entry.quantity_wait = pu.quantityOrderWait?? 0;
                            entry.quantity_confirmed = pu.quantityOrderConfirm?? 0;
                            entry.actualInventory = (entry.inventories - entry.quantity_ordered) < 0 ? 0 : (entry.inventories - entry.quantity_ordered);
                        }
                    });
                }
            }
            if(!this.productAttrActive){
                this.getStockCard(entry, null);
                this.getMaxInventory(entry.id, entry, null);
                this.getConsignmentForProduct(entry.id, entry, null);
            }
        },
        showModalCategoryAdd(){
            this.typeEditAdd = "ADD";
            this.idCategory = null;            
            this.$refs.ModalCategoryAdd.show();
        },
        showModalProductsAdd(){
            this.typeEditAdd = "ADD";
            this.idProduct = null;
            this.$refs.ModalProductsAdd.show();
        },
        showModalProductsEditById(value){
            this.typeEditAdd = "EDIT";
            this.idProduct = value;
            this.$refs.ModalProductsEdit.show();
        },
        async clearFilter() {
            await this.$router.replace({
                path: '/products/index',
                query: {}
            });
            Object.keys(this.filter).forEach(key => {
                this.filter[key] = '';
            });
            emitter.emit("clearQuickSearch");
            this.filterQuery = {};
            this.filterType = [];
            this.filterCategories = [];
            this.inputFilterCategories = '';
            this.sortData.field = null,
            this.sortData.direction = null,
            this.numberRecord = 15;
            this.headerActive = 'info';
            this.sttActive = null,
            this.productAttrActive = null;
            // this.detailProduct(null);
            this.productDetail = null;
            this.removeChecked();
            this.onInputFilterCategories();
            this.load();
        },
        showContent(name, entry, attribute = null) {
            this.headerActive = name;
            if(name && entry){
                switch (name) {
                    case 'stockCard':
                        this.getStockCard(entry, attribute);
                        break;
                    case 'maxInventory':
                        this.getMaxInventory(entry.id, entry, attribute);
                        break;
                    case 'inventory':
                        this.getMaxInventory(entry.id, entry, attribute);
                        break;
                    case 'batch':
                        this.getConsignmentForProduct(entry.id, entry, attribute)
                        break;
                    default:
                        break;
                }
            }
        },
        getCheckedIDs() {
            return this.entries.filter(e => e.checked).map(e => e.id);
        },
        handleCheck(value){
            let arr = this.filterType
            if(arr.includes(value)){
                arr = arr.filter(item => item !== value)
            }else{
                arr.push(value)
            }
            this.filterType = arr
            this.handleFilter('type',arr);
        },
        handleCheckCategories(value){
            let arr = this.filterCategories
            arr = (arr.includes(value) || !value) ? [] : [value]
            this.filterCategories = arr
            this.handleFilter('categories',arr);
        },
        handleFilter(key, value){
            let query = {...this.filterQuery,page:'1'}
            query[key] = JSON.stringify(value)
            this.filterQuery = query
            this.load(query)
        },
        async doFilter(field, value) {
            const query = clone(this.$route.query);
            query[field] = value;
            if(field == 'sttActive'){
                this.sttActive = value;
                if(value == null){
                    delete query[field];
                }
            }
            await this.$router.push({query: {...query,page:'1'}});
            const filterQuery = clone(this.filterQuery);
            this.load(filterQuery);
        },
        async sort(field, direction) {
            this.sortData = {
                field, direction
            };

            const query = clone(this.$route.query);
            query.sort_field = field;
            query.sort_direction = direction;

            await this.$router.push({ query: query });
            this.load();


        },
        async pageChange(page) {
            let query = clone(this.$route.query);
            await this.$router.push({query: query});
            let filterQuery = clone(this.filterQuery);
            filterQuery['page'] = page;
            this.filterQuery = filterQuery;
            this.load(filterQuery);
        },
        setStatusByBranch(entry){
            let status;
            let meta = entry.product_branch_meta;
            meta.forEach(item => {                
                if(item.productId == entry.id && store.state.CurrentBranch.id == item.branchId) status = item.status;
            });
            return status;
        },
        async load(query) {
            this.loading = true;
            emitter.emit("onLoading");
            let param =
            {
                ...this.$route.query,
                ...(query ? {...query} : clone(this.filterQuery)),
                branchID: this.branchID,
            }
            const res = await $get('/v1/products/data', param);                   
            if (res.code !== 200) {
                $alert(res);
                return;
            }            
            if (!this.fields || !this.fields?.length) {
                this.fields = res.data.fields;
            }

            this.paginate.totalRecord = res.data.entries.total;
            this.paginate.currentPage = res.data.entries.current_page;
            this.paginate.lastPage = res.data.entries.last_page;

            this.entries = res.data.entries.data;
            // this.statusBusiness = res.data.listStatus;
            this.isCheckedAll();
            emitter.emit("offLoading");
            this.loading = false;
            

        },

        async checkAllRecord () {
            let param =
            {
                ...this.$route.query,
                ...clone(this.filterQuery),
                branchID: this.branchID,
                checkAll: true
            }
            const res = await $get('/v1/products/data', param);
            this.arrayChecked = res;
            this.isCheckedAll();           
        },

        shortCreate (items) {
            var route = null;
            route = this.$router.resolve({
                name: items.name,
                query: {
                    'type': items.type
                }
            });
            storeModul.dispatch('productStore/setProductIds', this.arrayChecked);
            window.open(route.href, '_blank');
        },
        async getDataCategories(){
            const cRes = await $get('/v1/categories/getTreeCategories');
            this.categories = cRes.result.tree;
            this.displayCategories = cRes.result.tree;
        },
        async loadDetail(){
            await this.load();
            this.entries.forEach((entry) => {
                this.detailProduct(entry.id);
            });
        },
        async resetCount(){
            this.removeChecked();
        },
        async exportExcel () {
            emitter.emit("onLoading");
            let param =
            {
                ...this.$route.query,
                ...clone(this.filterQuery),
                branchID: this.branchID,
            }
            const res = await http.download('/v1/products/export', {fields: this.fields, ...param});
            const fileName = res.headers['content-disposition'].split('filename=')[1];
            var _blob = new Blob([res.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(_blob);
            link.setAttribute('download', fileName);
            link.click();
            emitter.emit("offLoading");
        },
        async exportCombo(productId){                        
            const res = await http.download('/v1/products/export-combo', {productId: productId});            
            const fileName = res.headers['content-disposition'].split('filename=')[1];            
            var _blob = new Blob([res.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(_blob);
            link.setAttribute('download', fileName);
            link.click();  
        },
        async exportDetailConsignment (product) 
        {
            this.filterQuery.export = true;
            await this.load();
            let id = this.getOSByID(product);
            const res = await http.download('/v1/products/export-consignment', {id: id});
            const fileName = res.headers['content-disposition'].split('filename=')[1];            
            var _blob = new Blob([res.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(_blob);
            link.setAttribute('download', fileName);
            link.click();  
            this.filterQuery.export = false; 
        },

        getOSByID(entries){                
                // let id = [];
                // entries.forEach(item => {
                //     id.push(item.id);
                // });                
                // return id;
                return entries;
            }, 
        showDetailStockCard(id, stockCardType){
            this.$refs.DetailStockCard.show(id, stockCardType);
        },
        copy(id){
            this.typeEditAdd = "COPY";
            this.idProduct = id;
            this.$refs.ModalProductsEdit.show();
            // this.$refs.ModalConfirmCopyProduct.show(id);
        },
        selectOptionExport(){
            let params =
            {
                ...this.$route.query,
                ...clone(this.filterQuery),
                branchID: this.branchID,
                method: 'exportProducts'
            };
            this.$refs.ModalSelectOptionExport.show(params, this.paginate.totalRecord, this.fields);
        },
        async exportFiles(params){
            if (params.method) {
                this[params.method](params.firstRecordIndex);
            }
        },
        async exportProducts(firstRecordIndex){
            emitter.emit("onLoading");
            const res = await http.download('/v1/products/export', {
                fields: this.fields, 
                ...this.$route.query,
                ...clone(this.filterQuery),
                branchID: this.branchID,
                firstRecordIndex: firstRecordIndex
            });
            this.returnDataExport(res);
        }
    },
    watch:{
        // state: {
        //     handler(newState) {  // here having access to the new and old value
        //         if(newState.CurrentBranch != null){
        //             this.branchID = newState.CurrentBranch?.id;
        //             this.load();
        //         }
        //     },
        //     deep: true,
        // },
        // entries: {
        //     handler(newData) {  // here having access to the new and old value
        //         newData.forEach(data => {
        //             if(data.id == this.productDetail){
        //                 if(data && data.stockCard){
        //                     data.stockCard.sort(function(a, b) { return new Date(b.date) - new Date(a.date) });
        //                     this.defaultStockCard.filter = this.defaultStockCard.all = data.stockCard;
        //                     this.setPaginateStockCard();
        //                 }
        //                 if(data && data.product_branch_meta){
        //                     const arr = [];
        //                     data.product_branch_meta.forEach(pbm => {
        //                         if(pbm.productUnitId == data.currentProductUnitId){
        //                             arr.push(pbm);
        //                         }
        //                     })
        //                     this.defaultProductBranchMeta.filter = this.defaultProductBranchMeta.all = arr;
        //                     this.defaultMaxInventory.filter = this.defaultMaxInventory.all = arr;
        //                     this.setPaginate();
        //                 }
        //                 // if(data && data.consignments){
        //                 //     this.defaultProductBatch.filter = this.defaultProductBatch.all = data.consignments;
        //                 //     this.defaultProductBatch.status = 0;
        //                 //     this.setPaginateBatch();
        //                 // }
        //             }
        //         })
        //     },
        //     deep: true,
        // }
    }
}
</script>

<style scoped>

    .dropdown:hover .dropdown-menu {
        display: block;
        margin-top: 0;
    }
    .img-container {
        display: flex;
        flex-direction: row;
        gap: 1rem
    }
    .img-list {
        display: flex;
        flex-direction: column;
    }
    .img-main-item {
        width: 15vw;
        object-fit: cover;
    }
    .text-title{
    line-height: 52px;
    }
    #number_records{
    margin-top: 11px;
    }
    #typeCard{
    margin-top: -8px;
    }
    .child-category-container {
        margin-left: 1rem
    }

    .child-category-item {
        display:flex;
        justify-content: space-between;
        margin-top: .5rem
    }
    .container input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    }
    .icon-search_input{
        position: absolute;
        right: 0;
        top: 40%;
    }
    .table-header th{
        height: 40px !important;
    }
    .table-header-search, .table-header-search:hover{
        height: 50px !important;
        background-color: #FEFCED !important;
    }
    .cell-code{
        width: 232px;
        min-width: 232px;
        max-width: 232px;
    }
    .cell-status{
        width: 150px;
        min-width: 150px;
        max-width: 150px;
    }
    .h-100{
        height: 100% !important;
    }

    .cell-name{
        max-width: 300px;
    }
</style>