<template>
    <div ref="modal" class="modal mx-0 fade" tabindex="-1" role="dialog" data-backdrop="static">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header py-1 border-bottom-0">
                    <h6 class="modal-title py-2 px-4" v-if="this.stockCardType == this.$constant.stockCardInvoice">{{$t('message.InvoiceIndex.title')}}</h6>
                    <h6 class="modal-title py-2 px-4" v-if="this.stockCardType == this.$constant.stockCardReturn">{{$t('message.InvoiceIndex.button.return')}}</h6>
                    <h6 class="modal-title py-2 px-4" v-if="this.stockCardType == this.$constant.stockCardTransfer">{{ $t('message.TransferIndex.title') }}</h6>
                    <h6 class="modal-title py-2 px-4" v-if="this.stockCardType == this.$constant.stockCardImportForSupplier">{{$t('message.PurchaseOrderIndex.title')}}</h6>
                    <h6 class="modal-title py-2 px-4" v-if="this.stockCardType == this.$constant.stockCardReturnForSupplier">{{$t('message.headers.return_purchase_orders')}}</h6>
                    <button type="button" class="close" aria-label="Close" @click="hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
    
                <div class="modal-body py-0 px-3">
                    <div class="tab-content" id="myTabContent" v-if="this.entries?.length > 0">
                        <div class="tab-pane fade show active" v-for="entry in this.entries" :key="entry.id">
                            <div class="row mx-4 my-0 pb-0 mt-1">
                                <div class="col-4 pl-0">
                                    <div class="row border-bottom-1 m-0">
                                        <div class="col-4 d-flex align-items-center pl-0" v-if="this.stockCardType == this.$constant.stockCardInvoice">{{$t('message.InputQuickSearch.title.code_invoice')}}:</div>
                                        <div class="col-4 d-flex align-items-center pl-0" v-if="this.stockCardType == this.$constant.stockCardReturn">{{$t('message.InputQuickSearch.title.returns_code')}}:</div>
                                        <div class="col-4 d-flex align-items-center pl-0" v-if="this.stockCardType == this.$constant.stockCardTransfer">{{$t('message.supplierIndex.table.code')}}:</div>
                                        <div class="col-4 d-flex align-items-center pl-0" v-if="this.stockCardType == this.$constant.stockCardImportForSupplier">{{$t('message.OrderSupplierIndex.purchase_code')}}:</div>
                                        <div class="col-4 d-flex align-items-center pl-0" v-if="this.stockCardType == this.$constant.stockCardReturnForSupplier">{{ $t('message.PurchaseOrderIndex.tabs.table.history_return.code') }}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1 text-bold" v-text="entry.code"></div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0" v-if="this.stockCardType == this.$constant.stockCardTransfer && entry.from_branch">
                                        <div class="col-4 d-flex align-items-center pl-0">{{$t('message.TransferIndex.from_branch')}}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="entry.from_branch ? entry.from_branch.name : null"></div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0" v-if="this.stockCardType == this.$constant.stockCardTransfer">
                                        <div class="col-4 d-flex align-items-center pl-0">{{$t('message.TransferIndex.transfer_date')}}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="dt(entry.deliveryDate)"></div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0" v-if="this.stockCardType != this.$constant.stockCardTransfer">
                                        <div class="col-4 d-flex align-items-center pl-0">{{$t('message.common.time')}}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="dt(entry.returnDate)" v-if="this.stockCardType == this.$constant.stockCardReturn"></div>
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="dt(entry.importDate)" v-else-if="this.stockCardType == this.$constant.stockCardImportForSupplier"></div>
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="dt(entry.returnDate)" v-else-if="this.stockCardType == this.$constant.stockCardReturnForSupplier"></div>
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="dt(entry.purchaseDate)" v-else></div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0" v-if="this.stockCardType == this.$constant.stockCardImportForSupplier || this.stockCardType == this.$constant.stockCardReturnForSupplier">
                                        <div class="col-4 d-flex align-items-center pl-0">{{$t('message.headers.suppliers')}}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1 text-primary">
                                                <a :href="'/suppliers/index?keyword=' + entry?.supplier?.code" target="_blank" class="text-decoration-none text-primary">{{ entry?.supplier?.name }}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0" v-if="(this.stockCardType == this.$constant.stockCardTransfer && entry.created_by_user) || (this.stockCardType == this.$constant.stockCardImportForSupplier && entry.createBy)">
                                        <div class="col-4 d-flex align-items-center pl-0">{{$t('message.common.created_by')}}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="entry.created_by_user ? entry.created_by_user.name : null" v-if="this.stockCardType == this.$constant.stockCardTransfer"></div>
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="entry.createBy ? entry.createBy : null" v-else></div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0" v-if="this.stockCardType != this.$constant.stockCardTransfer && this.stockCardType != this.$constant.stockCardImportForSupplier && this.stockCardType != this.$constant.stockCardReturnForSupplier">
                                        <div class="col-4 d-flex align-items-center pl-0">{{$t('message.headers.customers')}}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1 text-primary">
                                                <a :href="'/customers/index?code=' + entry.customer?.code" target="_blank" class="text-decoration-none text-primary">{{ entry.customerName }}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0" v-if="this.stockCardType != this.$constant.stockCardTransfer && this.stockCardType != this.$constant.stockCardImportForSupplier && this.stockCardType != this.$constant.stockCardReturnForSupplier">
                                        <div class="col-4 d-flex align-items-center pl-0">{{ $t('message.PriceListDetailIndex.priceList') }}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="entry.priceListName"></div>
                                        </div>
                                    </div>
                                    <div class="row m-0" v-if="this.stockCardType != this.$constant.stockCardTransfer && this.stockCardType != this.$constant.stockCardReturn && this.stockCardType != this.$constant.stockCardImportForSupplier && this.stockCardType != this.$constant.stockCardReturnForSupplier">
                                        <div class="col-4 d-flex align-items-center pl-0">{{$t('message.OrderIndex.code')}}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1">
                                                <a :href="'/orders/index?code=' + entry.orderCode" target="_blank" class="text-decoration-none text-primary">{{ entry.orderCode }}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4 pl-0">
                                    <div class="row border-bottom-1 m-0" v-if="this.stockCardType == this.$constant.stockCardImportForSupplier">
                                        <div class="col-4 d-flex align-items-center pl-0">{{$t('message.InputQuickSearch.title.order_supplier')}}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1 text-primary">
                                                <a :href="'/order-suppliers/index?order_supplier=' + entry.orderForSupplierId" target="_blank" class="text-decoration-none text-primary">{{ entry.orderForSupplierId }}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0">
                                        <div class="col-4 d-flex align-items-center pl-0">{{$t('message.common.status')}}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="entry.statusText" v-if="this.stockCardType == this.$constant.stockCardReturnForSupplier"></div>
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="entry.status" v-else></div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0" v-if="this.stockCardType == this.$constant.stockCardTransfer && entry.to_branch">
                                        <div class="col-4 d-flex align-items-center pl-0">{{$t('message.TransferIndex.to_branch')}}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="entry.to_branch ? entry.to_branch.name : null"></div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0" v-if="this.stockCardType == this.$constant.stockCardTransfer">
                                        <div class="col-4 d-flex align-items-center pl-0">{{$t('message.RequestIndex.receive_date')}}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="dt(entry.receivedDate)"></div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0" v-if="this.stockCardType == this.$constant.stockCardTransfer && entry.received_by_user">
                                        <div class="col-4 d-flex align-items-center pl-0">{{$t('message.CashBookIndex.receipt_by')}}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="entry.received_by_user ? entry.received_by_user.name : null"></div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0" v-if="this.stockCardType != this.$constant.stockCardTransfer">
                                        <div class="col-4 d-flex align-items-center pl-0">{{$t('message.common.branch')}}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="entry.branchId" v-if="this.stockCardType == this.$constant.stockCardImportForSupplier || this.stockCardType == this.$constant.stockCardReturnForSupplier"></div>
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="entry.branchName" v-else></div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0" v-if="this.stockCardType == this.$constant.stockCardReturn">
                                        <div class="col-4 d-flex align-items-center pl-0">{{ $t('message.InvoiceIndex.tabs.table.history_return.receive_by') }}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="entry.receiverName"></div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0" v-if="this.stockCardType == this.$constant.stockCardReturn">
                                        <div class="col-4 d-flex align-items-center pl-0">{{$t('message.CashBookIndex.modal.showDetail.code_payment')}}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1"></div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0" v-if="this.stockCardType != this.$constant.stockCardTransfer && this.stockCardType != this.$constant.stockCardReturn && this.stockCardType != this.$constant.stockCardImportForSupplier && this.stockCardType != this.$constant.stockCardReturnForSupplier">
                                        <div class="col-4 d-flex align-items-center pl-0">{{ $t('message.customerIndex.sellerName') }}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="entry.soldByName"></div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0" v-if="this.stockCardType != this.$constant.stockCardTransfer && this.stockCardType != this.$constant.stockCardImportForSupplier">
                                        <div class="col-4 d-flex align-items-center pl-0">{{$t('message.common.created_by')}}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="entry.createdByName" v-if="this.stockCardType == this.$constant.stockCardReturn"></div>
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="entry.createdBy" v-else-if="this.stockCardType == this.$constant.stockCardReturnForSupplier"></div>
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="entry.userCreatedName" v-else></div>
                                        </div>
                                    </div>
                                    <div class="row m-0" v-if="this.stockCardType != this.$constant.stockCardTransfer && this.stockCardType != this.$constant.stockCardReturn && this.stockCardType != this.$constant.stockCardImportForSupplier && this.stockCardType != this.$constant.stockCardReturnForSupplier">
                                        <div class="col-4 d-flex align-items-center pl-0">{{$t('message.SaleChannelIndex.title')}}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="entry.saleChannelName"></div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0" v-if="this.stockCardType == this.$constant.stockCardImportForSupplier || this.stockCardType == this.$constant.stockCardReturnForSupplier">
                                        <div class="col-4 d-flex align-items-center pl-0" v-text="(this.stockCardType == this.$constant.stockCardImportForSupplier ? $t('message.PurchaseOrderIndex.filter.import_by') : $t('message.PurchaseOrderIndex.tabs.table.history_return.receive_by')) + ':'"></div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="entry.userName"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4" v-if="this.stockCardType == this.$constant.stockCardTransfer">  
                                    <div class="row p-0 font-italic border-left mb-4">
                                        <div class="col-12 text-gray">
                                            <span class="noteStyle text-gray font-italic">
                                                {{ entry.sendDescription ? entry.sendDescription : $t('message.TransferIndex.from_branch_note')}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row p-0 font-italic border-left">
                                        <div class="col-12 text-gray">
                                            <span class="noteStyle text-gray font-italic">
                                                {{ entry.receiveDescription ? entry.receiveDescription : $t('message.TransferIndex.to_branch_note')}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4" v-if="this.stockCardType == this.$constant.stockCardReturn">  
                                    <div class="row p-0 font-italic border-left mb-4">
                                        <div class="col-12 text-gray">
                                            <span class="noteStyle text-gray font-italic">
                                                {{ entry.description ? entry.description : $t('message.ProductIndex.note')}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row p-0">
                                        <div class="col-4 d-flex align-items-center pl-0">{{$t('message.SaleChannelIndex.title')}}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="entry.saleChannelName"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4" id="noteRO" v-if="this.stockCardType != this.$constant.stockCardTransfer && this.stockCardType != this.$constant.stockCardReturn">  
                                    <div class="row p-0 font-italic">
                                        <div class="col-12 text-gray">
                                            <span class="noteStyle text-gray font-italic">
                                                {{ entry.description ? entry.description : (entry.note ? entry.note : $t('message.InvoiceIndex.note'))}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-2 mx-4 pb-2 mb-4">
                                <div class="row detail-prd-row mt-3 m-0 w-100">
                                    <div class="col-12 p-0">
                                        <table class="w-100">
                                            <thead>
                                                <tr class="backgroup__detail table-header border-1" v-if="this.stockCardType == this.$constant.stockCardInvoice">
                                                    <th scope="col" class="pl-4 cell-code-coupon p-2">{{$t('message.DetailOrdersCard.prodCode')}}</th>
                                                    <th scope="col" class="pl-4 cell-auto p-2">{{$t('message.DetailOrdersCard.prodName')}}</th>
                                                    <th scope="col" class="pl-4 cell-auto p-2">{{$t('message.DetailOrdersCard.prodUnit')}}</th>
                                                    <th scope="col" class="pl-4 cell-release-coupon p-2">{{$t('message.DetailOrdersCard.prodQuantity')}}</th>
                                                    <th scope="col" class="pl-4 cell-auto p-2">{{$t('message.DetailOrdersCard.prodPrice')}}</th>
                                                    <th scope="col" class="pl-4 cell-auto p-2">{{$t('message.DetailOrdersCard.prodDiscount')}}</th>
                                                    <th scope="col" class="pl-4 cell-auto p-2">{{$t('message.ProductIndex.price')}}</th>
                                                    <th scope="col" class="pl-4 cell-status-coupon p-2">{{$t('message.DetailOrdersCard.totalPrice')}}</th>
                                                </tr>
                                                <tr class="backgroup__detail table-header border-1" v-if="this.stockCardType == this.$constant.stockCardTransfer">
                                                    <th scope="col" class="pl-4 cell-code-coupon p-2">{{$t('message.DetailOrdersCard.prodCode')}}</th>
                                                    <th scope="col" class="pl-4 cell-auto p-2">{{$t('message.DetailOrdersCard.prodName')}}</th>
                                                    <th scope="col" class="pl-4 cell-auto p-2">{{$t('message.DetailOrdersCard.prodUnit')}}</th>
                                                    <th scope="col" class="pl-4 cell-release-coupon p-2">{{$t('message.common.quantityTransfer')}}</th>
                                                    <th scope="col" class="pl-4 cell-release-coupon p-2">{{$t('message.common.quantityReceive')}}</th>
                                                </tr>
                                                <tr class="backgroup__detail table-header border-1" v-if="this.stockCardType == this.$constant.stockCardReturn">
                                                    <th scope="col" class="pl-4 cell-code-coupon p-2">{{$t('message.DetailOrdersCard.prodCode')}}</th>
                                                    <th scope="col" class="pl-4 cell-auto p-2">{{$t('message.DetailOrdersCard.prodName')}}</th>
                                                    <th scope="col" class="pl-4 cell-auto p-2">{{$t('message.DetailOrdersCard.prodUnit')}}</th>
                                                    <th scope="col" class="pl-4 cell-release-coupon p-2">{{$t('message.DetailOrdersCard.prodQuantity')}}</th>
                                                    <th scope="col" class="pl-4 cell-release-coupon p-2">{{$t('message.ProductIndex.price')}}</th>
                                                    <th scope="col" class="pl-4 cell-release-coupon p-2">{{$t('message.DetailOrdersCard.prodDiscount')}}</th>
                                                    <th scope="col" class="pl-4 cell-release-coupon p-2">{{ $t('message.ReturnIndex.reImportPrice') }}</th>
                                                    <th scope="col" class="pl-4 cell-release-coupon p-2">{{$t('message.DetailOrdersCard.totalPrice')}}</th>
                                                </tr>
                                                <tr class="backgroup__detail table-header border-1" v-if="this.stockCardType == this.$constant.stockCardImportForSupplier">
                                                    <th scope="col" class="pl-4 cell-code-coupon p-2">{{$t('message.DetailOrdersCard.prodCode')}}</th>
                                                    <th scope="col" class="pl-4 cell-auto p-2">{{$t('message.DetailOrdersCard.prodName')}}</th>
                                                    <th scope="col" class="pl-4 cell-auto p-2">{{$t('message.DetailOrdersCard.prodUnit')}}</th>
                                                    <th scope="col" class="pl-4 cell-release-coupon p-2">{{$t('message.DetailOrdersCard.prodQuantity')}}</th>
                                                    <th scope="col" class="pl-4 cell-release-coupon p-2">{{$t('message.DetailOrdersCard.prodPrice')}}</th>
                                                    <th scope="col" class="pl-4 cell-release-coupon p-2">{{$t('message.DetailOrdersCard.prodDiscount')}}</th>
                                                    <th scope="col" class="pl-4 cell-release-coupon p-2">{{$t('message.DetailOrdersCard.prodImportPrice')}}</th>
                                                    <th scope="col" class="pl-4 cell-release-coupon p-2">{{$t('message.DetailOrdersCard.totalPrice')}}</th>
                                                </tr>
                                                <tr class="backgroup__detail table-header border-1" v-if="this.stockCardType == this.$constant.stockCardReturnForSupplier">
                                                    <th scope="col" class="pl-4 cell-code-coupon p-2">{{$t('message.DetailOrdersCard.prodCode')}}</th>
                                                    <th scope="col" class="pl-4 cell-auto p-2">{{$t('message.DetailOrdersCard.prodName')}}</th>
                                                    <th scope="col" class="pl-4 cell-auto p-2">{{$t('message.DetailOrdersCard.prodUnit')}}</th>
                                                    <th scope="col" class="pl-4 cell-release-coupon p-2">{{$t('message.DetailOrdersCard.prodQuantity')}}</th>
                                                    <th scope="col" class="pl-4 cell-release-coupon p-2">{{$t('message.DetailOrdersCard.prodImportPrice')}}</th>
                                                    <th scope="col" class="pl-4 cell-release-coupon p-2">{{$t('message.ReturnPurchaseOrder.returnPrice')}}</th>
                                                    <th scope="col" class="pl-4 cell-release-coupon p-2">{{$t('message.ReturnPurchaseOrder.returnDiscount')}}</th>
                                                    <th scope="col" class="pl-4 cell-release-coupon p-2">{{$t('message.DetailOrdersCard.totalPrice')}}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="table-header border">
                                                    <th scope="col" class="pl-4 py-2"> 
                                                        <input type="text" v-model="this.defaultValues.searchCode" @input="onInputFilterDetailOrderSupplier('searchCode')" :placeholder="$t('message.DetailOrdersCard.searchProductCode')" class="border-none bg-transparent">
                                                    </th>
                                                    <th scope="col" class="pl-4 py-2"> 
                                                        <input type="text" v-model="this.defaultValues.searchName" @input="onInputFilterDetailOrderSupplier('searchName')" :placeholder="$t('message.DetailOrdersCard.searchProductName')" class="border-none bg-transparent">
                                                    </th>
                                                    <th colspan="6"></th>
                                                </tr>
                                                <template v-if="this.defaultValues.filter?.length > 0 && this.stockCardType == this.$constant.stockCardInvoice">
                                                    <template v-for="(item, index) in this.defaultValues.filter" :key="item.id">
                                                        <template v-if="index >= (this.paginateDetail.currentPage - 1)*10 && index < this.paginateDetail.currentPage*10">
                                                            <tr class="table-header border"  :class="index % 2 ? 'bg-white' : 'bg-light'">
                                                                    <th scope="col" class="pl-4 cell-code-coupon p-2">
                                                                        <div class="row m-0 d-flex">
                                                                            <div class="col-1 p-0">
                                                                                <i class="fa fa-chevron-down" v-if="item.children_combo && item.children_combo.length > 0 && !item.showCombo" @click="showCombo(item)"></i>
                                                                                <i class="fa fa-chevron-up" v-if="item.children_combo && item.children_combo.length > 0 && item.showCombo" @click="showCombo(item)"></i>
                                                                            </div>
                                                                            <div class="col-10 p-0">
                                                                                <a :href="'/products/index?code=' + item.productCode" target="_blank" class="text-decoration-none text-primary">{{item.productCode}}</a>
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal">
                                                                        <span v-text="item.productName"></span>
                                                                        <span v-show="item.statusDiscountPromotion" class="ml-1 px-1 py-0 bg-danger text-white">
                                                                            {{$t('message.common.KM')}}
                                                                        </span>
                                                                        <span class="description font-italic text-gray" v-text="item.description" :title="item.description"></span>
                                                                    </th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="item.unitName"></th>
                                                                    <th scope="col" class="pl-4 cell-release-coupon p-2 font-weight-normal" v-text="n(item.quantity)"></th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="n(item.price)"></th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="n(item.discount)"></th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="n(item.price - item.discount)"></th>
                                                                    <th scope="col" class="pl-4 cell-status-coupon p-2 font-weight-normal" v-text="n(item.subTotal)"></th>
                                                            </tr>
                                                            <tr class="table-header border bg-white" v-if="item.consignments && item.consignments.length">
                                                                <th></th>
                                                                <th :colspan="7">
                                                                    <template v-if="item.consignments">
                                                                        <div v-for="(value, index) in item.consignments" :key="index" class="mr-1 mt-1 mb-2">
                                                                            <span class="consignment_name mr-2 text-white">{{ value.name + ' - ' + d(value.expried) + ' - ' + $t('message.common.shortQuantity') + ':' + value.quantity}}</span>
                                                                        </div>
                                                                    </template>
                                                                </th>
                                                            </tr>
                                                            <template v-if="item.children_combo && item.children_combo.length > 0 && item.showCombo">
                                                                <template v-for="(productInCombo, key) in item.children_combo" :key="key">
                                                                    <tr class="table-header border backgroup__detail">
                                                                        <th class="pl-4 p-2">
                                                                            <div class="row m-0 d-flex">
                                                                                <div class="col-1 p-0">
                                                                                </div>
                                                                                <div class="col-10 p-0 pl-2">
                                                                                    <span v-text="(key + 1) + '. '"></span>
                                                                                    <a :href="'/products/index?code=' + productInCombo.product.code" target="_blank" class="text-decoration-none text-primary">{{productInCombo.product.code}}</a>
                                                                                </div>
                                                                            </div>
                                                                        </th>
                                                                        <th class="pl-4 font-weight-normal">{{ productInCombo.product.name }}</th>
                                                                        <th class="pl-4 font-weight-normal">{{ productInCombo.product_unit.unitName }}</th>
                                                                        <th class="pl-4 font-weight-normal">{{ productInCombo.quantity }}</th>
                                                                        <th :colspan="4"></th>
                                                                    </tr>
                                                                    <template v-if="productInCombo.consignments">
                                                                        <tr class="table-header border backgroup__detail" v-for="(invoice_consignment_detail, keyDetail) in productInCombo.consignments" :key="keyDetail">
                                                                            <th class="pl-4 p-2"></th>
                                                                            <th class="pl-4 p-2">
                                                                                <span class="consignment_name mr-2 text-white font-weight-normal">{{ invoice_consignment_detail.name + ' - ' + d(invoice_consignment_detail.expried) + ' - ' + $t('message.common.shortQuantity') + ':' + invoice_consignment_detail.quantity}}</span>
                                                                            </th>
                                                                            <th class="pl-4 p-2"></th>
                                                                            <th class="pl-4 p-2"></th>
                                                                            <th :colspan="4"></th>
                                                                        </tr>
                                                                    </template>
                                                                </template>
                                                            </template>
                                                        </template>
                                                    </template>
                                                </template>
                                                <template v-if="this.defaultValues.filter?.length > 0 && this.stockCardType == this.$constant.stockCardTransfer">
                                                    <template v-for="(item, index) in this.defaultValues.filter" :key="item.id">
                                                        <template  v-if="index >= (this.paginateDetail.currentPage - 1)*10 && index < this.paginateDetail.currentPage*10" > 
                                                            <tr class="table-header border"  :class="index % 2 ? 'bg-white' : 'bg-light'">
                                                                <!-- <template v-if="index >= (this.paginateDetail.currentPage - 1)*10 && index < this.paginateDetail.currentPage*10"> -->
                                                                    <th scope="col" class="pl-4 cell-code-coupon p-2">
                                                                        <a :href="'/products/index?code=' + item.productCode" target="_blank" class="text-decoration-none text-primary">{{item.productCode}}</a>
                                                                    </th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal">
                                                                        <span v-text="item.productName"></span>
                                                                        <span class="description font-italic text-gray" v-text="item.sendDescription" :title="item.sendDescription"></span>
                                                                    </th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="item.product_unit_main?.unitName"></th>
                                                                    <th scope="col" class="pl-4 cell-release-coupon p-2 font-weight-normal" v-text="n(item.sendQuantity)"></th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="n(item.receiveQuantity)"></th>
                                                                <!-- </template> -->
                                                            </tr>
                                                            <tr class="table-header border bg-white" v-if="item.consignments && item.consignments.length">
                                                                <th></th>
                                                                <th :colspan="4">
                                                                    <template v-if="item.consignments">
                                                                        <div v-for="(value, index) in item.consignments" :key="index" class="mr-1 mt-1 mb-2">
                                                                            <span class="consignment_name mr-2 text-white">{{ value.name + ' - ' + d(value.expried) + ' - ' + $t('message.common.shortQuantity') + ':' + value.quantity}}</span>
                                                                        </div>
                                                                    </template>
                                                                </th>
                                                            </tr>
                                                        </template>
                                                    </template>
                                                </template>
                                                <template v-if="this.defaultValues.filter?.length > 0 && this.stockCardType == this.$constant.stockCardReturn">
                                                    <template v-for="(item, index) in this.defaultValues.filter" :key="item.id">
                                                        <template v-if="index >= (this.paginateDetail.currentPage - 1)*10 && index < this.paginateDetail.currentPage*10">
                                                            <tr class="table-header border" :class="index % 2 ? 'bg-white' : 'bg-light'">
                                                                <!-- <template > -->
                                                                    <th scope="col" class="pl-4 cell-code-coupon p-2">
                                                                        <div class="row m-0 d-flex">
                                                                            <div class="col-1 p-0">
                                                                                <i class="fa fa-chevron-down" v-if="item.children_combo && item.children_combo.length > 0 && !item.showCombo" @click="showCombo(item)"></i>
                                                                                <i class="fa fa-chevron-up" v-if="item.children_combo && item.children_combo.length > 0 && item.showCombo" @click="showCombo(item)"></i>
                                                                            </div>
                                                                            <div class="col-10 p-0">
                                                                                <a :href="'/products/index?code=' + item.productCode" target="_blank" class="text-decoration-none text-primary">{{item.productCode}}</a>
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal">
                                                                        <span v-text="item.productName"></span>
                                                                        <span class="description font-italic text-gray" v-text="item.note" :title="item.note"></span>
                                                                    </th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="item.unitName"></th>
                                                                    <th scope="col" class="pl-4 cell-release-coupon p-2 font-weight-normal" v-text="n(item.quantity)"></th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="n(item.price)"></th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="n(item.discountValue)"></th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="n(item.priceReturn)"></th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="n(item.subTotal)"></th>
                                                                <!-- </template> -->
                                                            </tr>
                                                            <tr class="table-header border bg-white" v-if="item.consignments && item.consignments.length">
                                                                <th></th>
                                                                <th :colspan="7">
                                                                    <template v-if="item.consignments">
                                                                        <div v-for="(value, index) in item.consignments" :key="index" class="mr-1 mt-1 mb-2">
                                                                            <span class="consignment_name mr-2 text-white">{{ value.name + ' - ' + d(value.expried) + ' - ' + $t('message.common.shortQuantity') + ':' + value.quantity}}</span>
                                                                        </div>
                                                                    </template>
                                                                </th>
                                                            </tr>
                                                            <template v-if="item.children_combo && item.children_combo.length > 0 && item.showCombo">
                                                                <template v-for="(productInCombo, key) in item.children_combo" :key="key">
                                                                    <tr class="table-header border backgroup__detail">
                                                                        <th class="pl-4 p-2">
                                                                            <div class="row m-0 d-flex">
                                                                                <div class="col-1 p-0">
                                                                                </div>
                                                                                <div class="col-10 p-0 pl-2">
                                                                                    <span v-text="(key + 1) + '. '"></span>
                                                                                    <a :href="'/products/index?code=' + productInCombo.product.code" target="_blank" class="text-decoration-none text-primary">{{productInCombo.product.code}}</a>
                                                                                </div>
                                                                            </div>
                                                                        </th>
                                                                        <th class="pl-4 font-weight-normal">{{ productInCombo.product.name }}</th>
                                                                        <th class="pl-4 font-weight-normal">{{ productInCombo.unit.unitName }}</th>
                                                                        <th class="pl-4 font-weight-normal">{{ productInCombo.quantity }}</th>
                                                                        <th :colspan="4"></th>
                                                                    </tr>
                                                                    <template v-if="productInCombo.consignments">
                                                                        <tr class="table-header border backgroup__detail" v-for="(return_detail_consignments, keyDetail) in productInCombo.consignments" :key="keyDetail">
                                                                            <th class="pl-4 p-2"></th>
                                                                            <th class="pl-4 p-2">
                                                                                <span class="consignment_name mr-2 text-white font-weight-normal">{{ return_detail_consignments.name + ' - ' + d(return_detail_consignments.expried) + ' - ' + $t('message.common.shortQuantity') + ':' + return_detail_consignments.quantity}}</span>
                                                                            </th>
                                                                            <th class="pl-4 p-2"></th>
                                                                            <th class="pl-4 p-2"></th>
                                                                            <th :colspan="4"></th>
                                                                        </tr>
                                                                    </template>
                                                                </template>
                                                            </template>
                                                        </template>
                                                    </template>
                                                </template>
                                                <template v-if="this.defaultValues.filter?.length > 0 && this.stockCardType == this.$constant.stockCardImportForSupplier">
                                                    <template v-for="(item, index) in this.defaultValues.filter" :key="item.id">
                                                        <template v-if="index >= (this.paginateDetail.currentPage - 1)*10 && index < this.paginateDetail.currentPage*10">
                                                            <tr class="table-header border" :class="index % 2 ? 'bg-white' : 'bg-light'">
                                                                <!-- <template > -->
                                                                    <th scope="col" class="pl-4 cell-code-coupon p-2">
                                                                        <a :href="'/products/index?code=' + item.product?.code" target="_blank" class="text-decoration-none text-primary">{{item.product?.code}}</a>
                                                                    </th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal">
                                                                        <span v-text="item.product?.name"></span>
                                                                        <span class="description font-italic text-gray" v-text="item.note" :title="item.note"></span>
                                                                    </th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="item.unit?.unitName"></th>
                                                                    <th scope="col" class="pl-4 cell-release-coupon p-2 font-weight-normal" v-text="n(item.quantity)"></th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="n(item.price)"></th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="n(item.discount / item.quantity)"></th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="n(item.price - (item.discount / item.quantity))"></th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="n(item.subTotal)"></th>
                                                                <!-- </template> -->
                                                            </tr>
                                                            <tr class="table-header border bg-white" v-if="item.consignments">
                                                                <th></th>
                                                                <th :colspan="7">
                                                                    <template v-if="item.consignments">
                                                                        <div v-for="(value, index) in item.consignments" :key="index" class="mr-1 mt-1 mb-2">
                                                                            <span class="consignment_name mr-2 text-white">{{ value.name + ' - ' + d(value.expried) + ' - ' + $t('message.common.shortQuantity') + ':' + value.quantity}}</span>
                                                                        </div>
                                                                    </template>
                                                                </th>
                                                            </tr>
                                                        </template>
                                                    </template>
                                                </template>
                                                <template v-if="this.defaultValues.filter?.length > 0 && this.stockCardType == this.$constant.stockCardReturnForSupplier">
                                                    <template v-for="(item, index) in this.defaultValues.filter" :key="item.id">
                                                        <template  v-if="index >= (this.paginateDetail.currentPage - 1)*10 && index < this.paginateDetail.currentPage*10">
                                                            <tr class="table-header border" :class="index % 2 ? 'bg-white' : 'bg-light'">
                                                                <!-- <template > -->
                                                                    <th scope="col" class="pl-4 cell-code-coupon p-2">
                                                                        <a :href="'/products/index?code=' + item.product?.code" target="_blank" class="text-decoration-none text-primary">{{item.product?.code}}</a>
                                                                    </th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal">
                                                                        <span v-text="item.product?.name"></span>
                                                                        <span class="description font-italic text-gray" v-text="item.note" :title="item.note"></span>
                                                                    </th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="item.unit?.unitName"></th>
                                                                    <th scope="col" class="pl-4 cell-release-coupon p-2 font-weight-normal" v-text="n(item.quantity)"></th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="n(item.importPrice)"></th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="n(item.price)"></th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="n(item.discount / item.quantity)"></th>
                                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="n(item.subTotal)"></th>
                                                                <!-- </template> -->
                                                            </tr>
                                                            <tr class="table-header border bg-white" v-if="item.consignments">
                                                                <th></th>
                                                                <th :colspan="7">
                                                                    <template v-if="item.consignments">
                                                                        <div v-for="(value, index) in item.consignments" :key="index" class="mr-1 mt-1 mb-2">
                                                                            <span class="consignment_name mr-2 text-white">{{ value.name + ' - ' + d(value.expried) + ' - ' + $t('message.common.shortQuantity') + ':' + value.quantity}}</span>
                                                                        </div>
                                                                    </template>
                                                                </th>
                                                            </tr>
                                                        </template>
                                                    </template>
                                                </template>
                                            </tbody>
                                        </table>
                                        <Pagination :value="paginateDetail" :record="10" v-if="this.paginateDetail.totalRecord >= 10"/>
                                        <div class="no-data border border-top-0" v-if="entry.invoice_items?.length == 0 || this.defaultValues.filter?.length == 0">
                                            <span>{{$t('message.common.notFound')}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mx-4 my-0 pb-4 mt-0" v-if="this.stockCardType != this.$constant.stockCardTransfer">
                                <div class="col-6">
                                    <div class="row mb-2" v-show="entry.coupon_detail">
                                        <div class="col-12 p-3 border-dotted-2 rounded">
                                            <p class="text-bold m-0">
                                                <i class="fa-solid fa-badge-percent"></i>{{$t('message.DetailOrdersCard.codeDiscount')}}
                                            </p>
                                            <span v-if="entry.messageCoupon" v-text="entry.messageCoupon"></span>
                                        </div>
                                    </div>
                                    <div class="row" v-show="entry.messagePromotion">
                                        <div class="col-12 p-3 border-dotted-2 rounded">
                                            <p class="text-bold m-0">
                                                <span class="btn-icon-gift mr-2 text-danger"><i class="fa fa-gift"></i></span>
                                                {{$t('message.DetailOrdersCard.promotion')}}
                                            </p>
                                            <span v-text="entry.messagePromotion"></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6" v-if="this.stockCardType == this.$constant.stockCardReturn">
                                    <div class="row m-2 mr-0">
                                        <div class="col-6 flex-end">{{$t('message.DetailOrdersCard.totalQuantity')}}:</div>
                                        <div class="col-6 p-0 flex-end" v-text="n(entry.totalProduct)"></div>
                                    </div>
                                    <div class="row m-2 mr-0">
                                        <div class="col-6 flex-end">{{$t('message.ReturnIndex.returnTotal')}}:</div>
                                        <div class="col-6 p-0 flex-end" v-text="n(entry.returnTotal)"></div>
                                    </div>
                                    <div class="row m-2 mr-0">
                                        <div class="col-6 flex-end">{{$t('message.ReturnIndex.returnDiscount')}}:</div>
                                        <div class="col-6 p-0 flex-end" v-text="n(entry.returnDiscount)"></div>
                                    </div>
                                    <div class="row m-2 mr-0">
                                        <div class="col-6 flex-end">{{$t('message.ReturnIndex.returnFee')}}:</div>
                                        <div class="col-6 p-0 flex-end" v-text="n(entry.returnFee)"></div>
                                    </div>
                                    <div class="row m-2 mr-0">
                                        <div class="col-6 flex-end">{{$t('message.ReturnIndex.returnCustomer')}}:</div>
                                        <div class="col-6 p-0 flex-end" v-text="n(entry.returnCustomer)"></div>
                                    </div>
                                    <div class="row m-2 mr-0">
                                        <div class="col-6 flex-end">{{$t('message.ReturnIndex.totalPayment')}}:</div>
                                        <div class="col-6 p-0 flex-end" v-text="n(entry.totalPayment)"></div>
                                    </div>
                                    <div class="row flex-end mt-4" >
                                        <button type="button" class="btn btn-save mx-1" @click="open(entry.code)">
                                            <i class="fa fa-share mr-2"></i>{{$t('message.DetailOrdersCard.btnOpen')}}
                                        </button>
                                    </div>
                                </div>
                                <div class="col-6" v-if="this.stockCardType != this.$constant.stockCardReturn">
                                    <div class="row m-2 mr-0">
                                        <div class="col-6 flex-end">{{$t('message.DetailOrdersCard.totalQuantity')}}:</div>
                                        <div class="col-6 p-0 flex-end" v-text="n(entry.totalQuantity)" v-if="this.stockCardType == this.$constant.stockCardImportForSupplier || this.stockCardType == this.$constant.stockCardReturnForSupplier"></div>
                                        <div class="col-6 p-0 flex-end" v-text="n(entry.totalProduct)" v-else></div>
                                    </div>
                                    <div class="row m-2 mr-0" v-if="this.stockCardType == this.$constant.stockCardImportForSupplier || this.stockCardType == this.$constant.stockCardReturnForSupplier">
                                        <div class="col-6 flex-end">{{$t('message.TransferIndex.total_products')}}:</div>
                                        <div class="col-6 p-0 flex-end" v-text="n(entry.totalProduct)" ></div>
                                    </div>
                                    <div class="row m-2 mr-0">
                                        <div class="col-6 flex-end">{{$t('message.DetailOrdersCard.total')}}:</div>
                                        <div class="col-6 p-0 flex-end" v-text="n(entry.totalPrice)" v-if="this.stockCardType == this.$constant.stockCardImportForSupplier || this.stockCardType == this.$constant.stockCardReturnForSupplier"></div>
                                        <div class="col-6 p-0 flex-end" v-text="n(entry.totalOrigin)" v-else></div>
                                    </div>
                                    <div class="row m-2 mr-0">
                                        <div class="col-6 flex-end" v-text="(this.stockCardType == this.$constant.stockCardImportForSupplier || this.stockCardType == this.$constant.stockCardReturnForSupplier) ? $t('message.DetailOrdersCard.prodDiscount') + ':' : $t('message.InvoiceIndex.discount') +':'"></div>
                                        <div class="col-6 p-0 flex-end" v-text="(this.stockCardType == this.$constant.stockCardImportForSupplier || this.stockCardType == this.$constant.stockCardReturnForSupplier) ? n(entry.priceDiscount) : n(entry.discountPrice)"></div>
                                    </div>
                                    <div class="row m-2 mr-0" v-if="this.stockCardType == this.$constant.stockCardImportForSupplier || this.stockCardType == this.$constant.stockCardReturnForSupplier">
                                        <div class="col-6 flex-end" v-text="(this.stockCardType == this.$constant.stockCardReturnForSupplier ? $t('message.ReturnPurchaseOrder.supplierNeedToPay') : $t('message.OrderIndex.total')) + ':'"></div>
                                        <div class="col-6 p-0 flex-end" v-text="n(entry.returnSupplier)"></div>
                                    </div>
                                    <div class="row m-2 mr-0" v-if="this.stockCardType == this.$constant.stockCardReturnForSupplier">
                                        <div class="col-6 flex-end">{{$t('message.ReturnPurchaseOrder.supplierPaid')}}:</div>
                                        <div class="col-6 p-0 flex-end" v-text="n(entry.supplierPayment)"></div>
                                    </div>
                                    <div class="row m-2 mr-0" v-if="this.stockCardType == this.$constant.stockCardImportForSupplier">
                                        <div class="col-6 flex-end">{{$t('message.OrderSupplierIndex.total_payment')}}:</div>
                                        <div class="col-6 p-0 flex-end" v-text="n(entry.paymentForSupplier)"></div>
                                    </div>
                                    <div class="row m-2 mr-0" v-if="this.stockCardType != this.$constant.stockCardImportForSupplier && this.stockCardType != this.$constant.stockCardReturnForSupplier">
                                        <div class="col-6 flex-end">{{$t('message.InvoiceIndex.total')}}:</div>
                                        <div class="col-6 p-0 flex-end" v-text="n(entry.total)"></div>
                                    </div>
                                    <div class="row m-2 mr-0" v-if="this.stockCardType != this.$constant.stockCardImportForSupplier && this.stockCardType != this.$constant.stockCardReturnForSupplier">
                                        <div class="col-6 flex-end">{{$t('message.InvoiceIndex.total_payment')}}:</div>
                                        <div class="col-6 p-0 flex-end" v-text="n(entry.totalPayment)"></div>
                                    </div>
                                    <div class="row flex-end mt-4" >
                                        <button type="button" class="btn btn-save mx-1" @click="open(entry.code)">
                                            <i class="fa fa-share mr-2"></i>{{$t('message.DetailOrdersCard.btnOpen')}}
                                        </button>
                                        <!-- <button type="button" class="btn btn-secondary mx-1" v-if="this.stockCardType == this.$constant.stockCardImportForSupplier">
                                            <i class="fa fa-ellipsis-v mr-2"></i>
                                        </button> -->
                                        <!-- <button class="btn btn-white border-1 dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-if="this.stockCardType == this.$constant.stockCardImportForSupplier">
                                            <i class="fa fa-ellipsis-v mr-2" aria-hidden="true"></i>
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <a class="dropdown-item text-dark">
                                                <i class="fa fa-file-export px-2"></i>
                                                <label>
                                                    {{$t('message.button.export.name')}}
                                                </label>
                                            </a>
                                            <a class="dropdown-item text-dark">
                                                <i class="fa fa-copy px-2"></i>
                                                <label>
                                                    {{$t('message.button.copy.name')}}
                                                </label>
                                            </a>
                                            <a class="dropdown-item text-dark">
                                                <i class="fa fa-print px-2"></i>
                                                <label>
                                                    {{$t('message.button.print.name')}}
                                                </label>
                                            </a>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {$post, removeVietnameseTones} from "@/lib/utils";
import permissionMixin from '@/permissionMixin';
import store from "@/store";

export default {
    mixins: [permissionMixin],
    name: "DetailStockCard",
    components: {Pagination},
    data () {
        return {
            entries: {},
            paginateDetail: {
                currentPage: 1,
                lastPage: 1,
            },
            stockCardType: null,
            defaultValues:{
                filter: null,
                all: null,
                searchCode: '',
                searchName: '',
            },
            userId: store.state.Auth.user.id,
        }   
    },
    created() {
    },
    methods: {
        async show (id = null, stockCardType) {
            this.clearData();
            if (id && stockCardType) {
                this.stockCardType = stockCardType;
                switch (stockCardType) {
                    case this.$constant.stockCardInvoice:
                        if(this.hasPermission(this.$permissions.invoices.data ?? '') || this.userId === this.$constant.isAdmin){
                            this.getDataInvoiceById(id);
                        }
                        // this.getDataInvoiceById(id);
                        break;
                    case this.$constant.stockCardReturn:
                        if(this.hasPermission(this.$permissions.returns.data ?? '') || this.userId === this.$constant.isAdmin){
                            this.getDataReturnById(id);
                        }
                        // this.getDataReturnById(id);
                        break;
                    case this.$constant.stockCardTransfer:
                        if(this.hasPermission(this.$permissions.transfers.data ?? '') || this.userId === this.$constant.isAdmin){
                            this.getDataTransferById(id);
                        }
                        // this.getDataTransferById(id);
                        break;
                    case this.$constant.stockCardImportForSupplier:
                        if(this.hasPermission(this.$permissions.purchaseOrders.data ?? '') || this.userId === this.$constant.isAdmin){
                            this.getDataImportForSupplierById(id);
                        }
                        // this.getDataImportForSupplierById(id);
                        break;                    
                    case this.$constant.stockCardOrderForSupplier:                     
                        if(this.hasPermission(this.$permissions.orderSuppliers.data ?? '') || this.userId == this.$constant.isAdmin){                                                           
                            this.getDataOrderForSupplierById(id);
                        }                        
                        break;
                    case this.$constant.stockCardReturnForSupplier:
                        if(this.hasPermission(this.$permissions.returnImportForSupplier.data ?? '') || this.userId === this.$constant.isAdmin){
                            this.getDataReturnForSupplierById(id);
                        }
                        // this.getDataReturnForSupplierById(id);
                        break;
                }
            }
        },
        async getDataInvoiceById(id){
            const res = await $post('/v1/invoices/show', {id: id});
            if(res && res.result){
                if(res.result.status && res.result.data != []){
                    this.entries = res.result.data;
                    if(this.entries){
                        this.entries.forEach(entry => {
                            if(entry.invoice_items){
                                this.defaultValues.filter = this.defaultValues.all = entry.invoice_items;
                                this.setPaginate(entry.invoice_items);
                            }
                        });
                    }
                    this.setPaginate();
                    window.jQuery(this.$refs.modal).modal('show');
                }
            }
        },
        async getDataTransferById(id){
            const res = await $post('/v1/transfers/show', {id: id});
            if(res && res.result){
                if(res.result.status && res.result.data != []){
                    this.entries = res.result.data;
                    if(this.entries){
                        this.entries.forEach(entry => {
                            if(entry.details){
                                this.defaultValues.filter = this.defaultValues.all = entry.details;
                                this.setPaginate(entry.details);
                            }
                        });
                    }
                    window.jQuery(this.$refs.modal).modal('show');
                }
            }
        },
        async getDataReturnById(id){
            const res = await $post('/v1/returns/show', {id: id});
            if(res && res.result){
                if(res.result.status && res.result.data != []){
                    this.entries = res.result.data;
                    if(this.entries){
                        this.entries.forEach(entry => {
                            if(entry.details){
                                this.defaultValues.filter = this.defaultValues.all = entry.details;
                                this.setPaginate(entry.details);
                            }
                        });
                    }
                    window.jQuery(this.$refs.modal).modal('show');
                }
            }
        },
        async getDataImportForSupplierById(id){
            const res = await $post('/v1/purchase-orders/show-import-supplier', {id: id});
            if(res && res.result){
                if(res.result.status && res.result.data != []){
                    this.entries = res.result.data;
                    if(this.entries){
                        this.entries.forEach(entry => {
                            if(entry.details){
                                this.defaultValues.filter = this.defaultValues.all = entry.details;
                                this.setPaginate(entry.details);
                            }
                        });
                    }
                    window.jQuery(this.$refs.modal).modal('show');
                }
            }
        },
        async getDataOrderForSupplierById(id){            
            const res = await $post('/v1/order-suppliers/detail', {id: id});                             
            if(res && res.status.code == 200){
                if(res.result){                                      
                    this.entries = [res.result];                                        
                    if(this.entries){                                              
                        this.entries.forEach(entry => {
                            
                            if(entry.details){
                                this.defaultValues.filter = this.defaultValues.all = entry.details;
                               
                            }
                        });
                    }
                    window.jQuery(this.$refs.modal).modal('show');
                }
            }
        },
        async getDataReturnForSupplierById(id){
            const res = await $post('/v1/purchase-orders/show-return-supplier', {id: id});
            if(res && res.result){
                if(res.result.status && res.result.data != []){
                    this.entries = res.result.data;
                    if(this.entries){
                        this.entries.forEach(entry => {
                            if(entry.details){
                                this.defaultValues.filter = this.defaultValues.all = entry.details;
                                this.setPaginate(entry.details);
                            }
                        });
                    }
                    window.jQuery(this.$refs.modal).modal('show');
                }
            }
        },
        hide () {
            this.clearData();
            window.jQuery(this.$refs.modal).modal('hide');
        },
        setPaginate(items){
            let arr = [];
            if(items){
                arr = items;
            }
            this.paginateDetail.currentPage = 1;
            this.paginateDetail.lastPage = Math.ceil(arr.length / 10);
            this.paginateDetail.totalRecord = arr.length;
        },
        onInputFilterDetailOrderSupplier(field){
            this.paginateDetail.currentPage = 1;
            if(this.defaultValues.searchCode.length == 0 && this.defaultValues.searchName.length == 0){
                this.defaultValues.filter = this.defaultValues.all;
                this.setPaginate(this.defaultValues.filter);
                return;
            }
            let arr = [];
            switch (field) {
                case 'searchCode':
                    for (let index = 0; index < this.defaultValues.all.length; index++) {
                        if (this.defaultValues.all[index].product != null && removeVietnameseTones(this.defaultValues.all[index].product?.code.toLowerCase()).indexOf(removeVietnameseTones(this.defaultValues.searchCode.toLowerCase())) > -1){
                            arr.push(this.defaultValues.all[index]);
                        }
                    }
                    break;
                case 'searchName':
                    for (let index = 0; index < this.defaultValues.all.length; index++) {
                        if (this.defaultValues.all[index].product != null && removeVietnameseTones(this.defaultValues.all[index].product?.name.toLowerCase()).indexOf(removeVietnameseTones(this.defaultValues.searchName.toLowerCase())) > -1){
                            arr.push(this.defaultValues.all[index]);
                        }
                    }
                    break;
            }
            this.defaultValues.filter = arr;
            this.setPaginate(arr);
        },
        clearData(){
            this.entries = {};
            this.paginateDetail = {
                currentPage: 1,
                lastPage: 1,
            };
            this.stockCardType = null;
            this.defaultValues = {
                filter: null,
                all: null,
                searchCode: '',
                searchName: '',
            };
        },
        open(code){
            var route = null;
            switch (this.stockCardType) {
                case this.$constant.stockCardInvoice:
                    route = this.$router.resolve({
                        path: '/invoices/index',
                        query: {
                            'code': code,
                        }
                    });
                    break;
                case this.$constant.stockCardReturn:
                    route = this.$router.resolve({
                        path: '/returns/index',
                        query: {
                            'code': code,
                        }
                    });
                    break;
                case this.$constant.stockCardTransfer:
                    route = this.$router.resolve({
                        path: '/transfer/index',
                        query: {
                            'code': code,
                        }
                    });
                    break;
                case this.$constant.stockCardImportForSupplier:
                    route = this.$router.resolve({
                        path: '/purchase-orders/index',
                        query: {
                            'purchase_order': code,
                        }
                    });
                    break;
                case this.$constant.stockCardReturnForSupplier:
                    route = this.$router.resolve({
                        path: '/purchase-orders/return',
                        query: {
                            'return_purchase_order': code,
                        }
                    });
                    break;
            }
            window.open(route.href, '_blank');
        },
        showCombo(entry){
            if(!entry.showCombo){
                entry.showCombo = true;
            }else{
                entry.showCombo = !entry.showCombo;
            }
        },
    },
    beforeUnmount() {
        this.clearData();
    },
    unmounted() {
        this.clearData();
    }
}
</script>
<style scoped>
#modalChecked {
    overflow-x: hidden;
    overflow-y: auto;
}

#modalTableChecked thead th {
    border-bottom: 0 !important;
}

@media (max-width: 1280px) {
    .modal-xl {
        width: auto !important;
    }
}

#noteRO {
    border-left: 1px solid #ccc;
}
.noteStyle {
    width: 100% !important;
    height: 120px !important;
    border: 0;
    border-radius: 0;
}
.noteStyle:focus {
    outline: none !important;
    border-color: none;
    box-shadow: 0 none;
}
.cate_selected{
    min-height: 30px !important;
    width:fit-content !important;
}
.area_obj_selected{
    max-height: 100px !important;
    min-height: 35px !important;
    overflow-y: auto;
}
.border-dotted-2{
    border: 1px dashed #ccc;
}
</style>